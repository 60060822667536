import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { CampaignGiftType } from '@social-garden/utils/types.ts';
import {
  acceptCampaignApplicationDocument,
  priceForCreatorHasCampaignDocument,
} from '@social-garden/api/documents/creatorHasCampaign.ts';
import {
  AcceptCampaignApplicationFieldValues,
  AcceptCampaignApplicationSchema,
} from '../../constants/ValidationSchema.ts';
import Section from '../../components/Section.tsx';
import GiftCodeField from '../../components/GiftCodeField.tsx';
import PriceSummary from '../../components/PriceSummary.tsx';

interface AcceptCampaignApplicationDialogProps {
  open: boolean;
  creatorHasCampaign: {
    id: string;
    rewardValue: number;
    campaign: {
      gifts: {
        id: string;
        name: string;
        type: CampaignGiftType;
      }[];
    };
  };
  onClose: () => void;
}

export default function AcceptCampaignApplicationDialog({
  open,
  creatorHasCampaign,
  onClose,
}: AcceptCampaignApplicationDialogProps) {
  const { t } = useTranslation(['common', 'manager']);
  const { enqueueSnackbar } = useSnackbar();

  const [
    priceForCreatorHasCampaign,
    { data, loading: priceForCreatorHasCampaignLoading },
  ] = useLazyQuery(priceForCreatorHasCampaignDocument, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<AcceptCampaignApplicationFieldValues>({
    mode: 'all',
    resolver: zodResolver(AcceptCampaignApplicationSchema),
    defaultValues: {
      campaignGiftCodes: creatorHasCampaign.campaign.gifts
        .filter((gift) => gift.type === CampaignGiftType.CODE)
        .map((gift) => ({
          campaignGiftId: gift.id,
          code: '',
        })),
    },
  });

  const { fields: giftCodeFields } = useFieldArray<
    AcceptCampaignApplicationFieldValues,
    'campaignGiftCodes'
  >({
    control,
    name: 'campaignGiftCodes',
  });

  const [
    acceptCampaignApplication,
    {
      loading: acceptCampaignApplicationLoading,
      error: acceptCampaignApplicationError,
    },
  ] = useMutation(acceptCampaignApplicationDocument);

  const handleOnAcceptApplication = useCallback(
    async ({ campaignGiftCodes }: AcceptCampaignApplicationFieldValues) => {
      if (data === undefined) {
        return;
      }
      const response = await acceptCampaignApplication({
        variables: {
          input: {
            creatorHasCampaignId: creatorHasCampaign.id,
            campaignGiftCodes:
              campaignGiftCodes.length > 0 ? campaignGiftCodes : undefined,
            price: data.priceForCreatorHasCampaign,
          },
        },
      });
      if (response.data?.acceptCampaignApplication) {
        onClose();
      }
    },
    [acceptCampaignApplication, creatorHasCampaign.id, data, onClose],
  );

  useEffect(() => {
    if (
      acceptCampaignApplicationError?.message ===
      'Payment could not be completed!'
    ) {
      enqueueSnackbar(t('manager:acceptCampaignApplication.paymentError'), {
        variant: 'info',
      });
    }
  }, [acceptCampaignApplicationError, enqueueSnackbar, t]);

  useEffect(() => {
    (async () => {
      if (open) {
        await priceForCreatorHasCampaign({
          variables: {
            creatorHasCampaignId: creatorHasCampaign.id,
          },
        });
      }
    })();
  }, [open, creatorHasCampaign.id, priceForCreatorHasCampaign]);

  return (
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        noValidate: true,
        onSubmit: handleSubmit(handleOnAcceptApplication),
      }}
      onClose={onClose}>
      <DialogTitle>
        {t('manager:acceptCampaignApplication.dialog.title')}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={4}>
          {giftCodeFields.length > 0 ? (
            <Section
              header={t('manager:acceptCampaignApplication.giftCode.header')}
              subHeader={t(
                'manager:acceptCampaignApplication.giftCode.subHeader',
              )}>
              {giftCodeFields.map((field, index) => (
                <Stack key={field.id} spacing={1}>
                  <Typography variant="subtitle2">
                    {
                      creatorHasCampaign.campaign.gifts.find(
                        (gift) => gift.id === field.campaignGiftId,
                      )?.name
                    }
                  </Typography>
                  <Controller<
                    AcceptCampaignApplicationFieldValues,
                    `campaignGiftCodes.${typeof index}.code`
                  >
                    name={`campaignGiftCodes.${index}.code`}
                    control={control}
                    render={GiftCodeField}
                  />
                </Stack>
              ))}
            </Section>
          ) : null}
          {data && !priceForCreatorHasCampaignLoading ? (
            <Section
              header={t(
                'manager:acceptCampaignApplication.priceSummary.header',
              )}>
              <PriceSummary
                price={data.priceForCreatorHasCampaign}
                rewardValue={creatorHasCampaign.rewardValue}
              />
            </Section>
          ) : (
            <Stack
              minHeight={200}
              width={600}
              alignItems="center"
              justifyContent="center">
              <CircularProgress />
            </Stack>
          )}
          {data &&
          !priceForCreatorHasCampaignLoading &&
          data.priceForCreatorHasCampaign > 0 ? (
            <Typography variant="caption">
              <Trans
                i18nKey="acceptCampaignApplication.paid.disclaimer"
                ns="manager"
                components={[
                  <Link
                    key="tos"
                    href={import.meta.env.VITE_TERMS_OF_SERVICE_URL}
                    target="_blank"
                  />,
                ]}
              />
            </Typography>
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button type="reset" color="inherit" onClick={onClose}>
          {t('common:cancel')}
        </Button>
        {data && !priceForCreatorHasCampaignLoading ? (
          <Button
            type="submit"
            variant="contained"
            color="success"
            disabled={!isValid || acceptCampaignApplicationLoading}>
            {data.priceForCreatorHasCampaign > 0
              ? t('manager:acceptCampaignApplication.paid.acceptButton')
              : t('manager:acceptCampaignApplication.free.acceptButton')}
          </Button>
        ) : (
          <Button variant="contained" color="success" disabled>
            {t('common:isCalculating')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
