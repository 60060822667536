import { graphql } from '../gql';

export const creatorHasCampaignMessageHeaderDocument = graphql(`
  query creatorHasCampaignMessageHeader($id: UUID!) {
    creatorHasCampaign(id: $id) {
      id
      creator {
        id
        username
        profilePicture {
          hash
          uri
        }
      }
      campaign {
        id
        name
      }
    }
  }
`);

export const myCreatorHasCampaignsDocument = graphql(`
  query myCreatorHasCampaigns(
    $input: MyCreatorHasCampaignsInput!
    $first: Int!
    $page: Int
  ) {
    myCreatorHasCampaigns(input: $input, first: $first, page: $page) {
      data {
        id
        status
        campaign {
          id
          name
          headerImage {
            uri
            hash
          }
          brand {
            id
            name
            logo {
              uri
              hash
            }
          }
        }
      }
      paginatorInfo {
        hasMorePages
        currentPage
      }
    }
  }
`);

export const myCreatorHasCampaignForCampaignDocument = graphql(`
  query myCreatorHasCampaignForCampaign($campaignId: UUID!) {
    myCreatorHasCampaignForCampaign(campaignId: $campaignId) {
      id
      status
      contentUrl
      discontinuedReason
      rewardValue
      answers {
        id
        answer
        question {
          id
          question
        }
      }
      previewRevisions {
        id
        caption
        retriedReason
      }
      channel {
        ...channel
      }
    }
  }
`);

export const campaignGiftCodeDocument = graphql(`
  query campaignGiftCode($campaignId: UUID!) {
    myCreatorHasCampaignForCampaign(campaignId: $campaignId) {
      id
      campaignGiftCodes {
        id
        code
        gift {
          id
        }
      }
    }
  }
`);

export const creatorsOfCampaignDocument = graphql(`
  query creatorsOfCampaign(
    $input: CreatorsOfCampaignInput!
    $first: Int!
    $page: Int
  ) {
    creatorsOfCampaign(input: $input, first: $first, page: $page) {
      data {
        id
        status
        rewardValue
        channel {
          ...channel
        }
        creator {
          id
          username
          isUnreliable
          profilePicture {
            uri
            hash
          }
        }
      }
      paginatorInfo {
        currentPage
        hasMorePages
      }
    }
  }
`);

export const creatorHasCampaignDocument = graphql(`
  query creatorHasCampaign($id: UUID!) {
    creatorHasCampaign(id: $id) {
      id
      rewardValue
      status
      discontinuedReason
      contentUrl
      rewardValue
      channel {
        ...channel
        channelable {
          ... on InstagramChannel {
            ageFollowerDemographics {
              id
              age
              percentage
            }
            countryFollowerDemographics {
              id
              country
              percentage
            }
            genderFollowerDemographics {
              id
              gender
              percentage
            }
          }
        }
      }
      campaignGiftCodes {
        id
        code
        gift {
          id
          type
          name
        }
      }
      answers {
        id
        answer
        question {
          id
          question
        }
      }
      deliveryAddress {
        id
        recipientName
        streetAddressLine1
        streetAddressLine2
        postcode
        city
        country
      }
      previewRevisions {
        id
        caption
        retriedReason
      }
      creator {
        id
        username
        description
        isUnreliable
        profilePicture {
          uri
          hash
        }
      }
      campaign {
        id
        name
        headerImage {
          uri
          hash
        }
        includeContentFileOnSubmission
        ...campaignTypeable
        gifts {
          id
          type
          name
        }
        questions {
          id
          question
        }
      }
    }
  }
`);

export const previewFileDocument = graphql(`
  query previewFile($id: UUID!) {
    previewFile(id: $id) {
      uri
      hash
    }
  }
`);

export const creatorHasCampaignContentFileDocument = graphql(`
  query creatorHasCampaignContentFile($id: UUID!) {
    creatorHasCampaignContentFile(id: $id) {
      uri
      hash
    }
  }
`);

export const priceForCreatorHasCampaignDocument = graphql(`
  query priceForCreatorHasCampaign($creatorHasCampaignId: UUID!) {
    priceForCreatorHasCampaign(creatorHasCampaignId: $creatorHasCampaignId)
  }
`);

export const applyForCampaignDocument = graphql(`
  mutation applyForCampaign($input: ApplyForCampaignInput!) {
    applyForCampaign(input: $input) {
      id
      status
      rewardValue
      campaign {
        id
      }
    }
  }
`);

export const unapplyFromCampaignDocument = graphql(`
  mutation unapplyFromCampaign($creatorHasCampaignId: UUID!) {
    unapplyFromCampaign(creatorHasCampaignId: $creatorHasCampaignId)
  }
`);

export const submitPreviewDocument = graphql(`
  mutation submitPreview($input: SubmitPreviewInput!) {
    submitPreview(input: $input) {
      id
      status
      previewRevisions {
        id
        caption
      }
    }
  }
`);

export const submitContentDocument = graphql(`
  mutation submitContent($input: SubmitContentInput!) {
    submitContent(input: $input) {
      id
      status
      contentUrl
    }
  }
`);

export const acceptCampaignApplicationDocument = graphql(`
  mutation acceptCampaignApplication($input: AcceptCampaignApplicationInput!) {
    acceptCampaignApplication(input: $input) {
      id
      status
      campaignGiftCodes {
        id
        code
        gift {
          id
          type
          name
          description
        }
      }
    }
  }
`);

export const declineCampaignApplicationDocument = graphql(`
  mutation declineCampaignApplication($creatorHasCampaignId: UUID!) {
    declineCampaignApplication(creatorHasCampaignId: $creatorHasCampaignId) {
      id
      status
    }
  }
`);

export const acceptPreviewDocument = graphql(`
  mutation acceptPreview($creatorHasCampaignId: UUID!) {
    acceptPreview(creatorHasCampaignId: $creatorHasCampaignId) {
      id
      status
    }
  }
`);

export const retryPreviewDocument = graphql(`
  mutation retryPreview($input: RetryPreviewInput!) {
    retryPreview(input: $input) {
      id
      status
      previewRevisions {
        id
        retriedReason
      }
    }
  }
`);

export const updateApplyUntilDocument = graphql(`
  mutation updateApplyUntil($input: UpdateApplyUntilInput!) {
    updateApplyUntil(input: $input) {
      id
      applyUntil
    }
  }
`);

export const updateRewardValueDocument = graphql(`
  mutation updateRewardValue($input: UpdateRewardValueInput!) {
    updateRewardValue(input: $input) {
      id
      rewardValue
    }
  }
`);
