/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A datetime string with format `Y-m-d\TH:i:sP`, e.g. `2024-07-12T10:00:59+00:0`. */
  DateTime: { input: string; output: string };
  /** Input: string with format `Y-m-d`, e.g. `2018-05-23` Output: string (time always set to 23:59:59) with format `Y-m-d\TH:i:sP`, e.g. `2024-07-12T23:59:59+00:0`. */
  DueDate: { input: string; output: string };
  UUID: { input: string; output: string };
  /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
  Upload: { input: File; output: any };
};

export type AcceptCampaignApplicationInput = {
  campaignGiftCodes?: InputMaybe<Array<CampaignGiftCodeInput>>;
  creatorHasCampaignId: Scalars['UUID']['input'];
  price: Scalars['Int']['input'];
};

export type AddInstagramChannelInput = {
  accessToken: Scalars['String']['input'];
  instagramBusinessAccountId: Scalars['String']['input'];
};

export type AddTiktokChannelInput = {
  code: Scalars['String']['input'];
};

export type AddYoutubeChannelInput = {
  code: Scalars['String']['input'];
};

export type AgeFollowerDemoGraphics = {
  __typename: 'AgeFollowerDemoGraphics';
  age: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  percentage: Scalars['Float']['output'];
};

export type Answer = {
  __typename: 'Answer';
  answer: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  question: Question;
};

export type AnswerInput = {
  answer: Scalars['String']['input'];
  questionId: Scalars['UUID']['input'];
};

export type ApplyForCampaignInput = {
  answers?: InputMaybe<Array<AnswerInput>>;
  campaignId: Scalars['UUID']['input'];
  deliveryAddress?: InputMaybe<DeliveryAddressInput>;
  dynamicMoneyRewardValue?: InputMaybe<Scalars['Int']['input']>;
  variableMoneyRewardValue?: InputMaybe<Scalars['Int']['input']>;
};

export type Authenticatable = CreatorAuthenticatable | ManagerAuthenticatable;

export type Brand = {
  __typename: 'Brand';
  id: Scalars['UUID']['output'];
  logo: Download;
  name: Scalars['String']['output'];
};

export type Campaign = {
  __typename: 'Campaign';
  address?: Maybe<Scalars['String']['output']>;
  applyUntil?: Maybe<Scalars['DueDate']['output']>;
  brand: Brand;
  category: Category;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  fixedMoneyRewardValue?: Maybe<Scalars['Int']['output']>;
  gifts: Array<CampaignGift>;
  headerImage: Download;
  id: Scalars['UUID']['output'];
  includeContentFileOnSubmission: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  questions: Array<Question>;
  rewardType: CampaignRewardType;
  status: CampaignStatus;
  taskItems: Array<TaskItem>;
  typeable: CampaignTypeable;
  updatedAt: Scalars['DateTime']['output'];
};

export type CampaignGift = {
  __typename: 'CampaignGift';
  description: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  type: CampaignGiftType;
};

export type CampaignGiftCode = {
  __typename: 'CampaignGiftCode';
  code: Scalars['String']['output'];
  gift: CampaignGift;
  id: Scalars['UUID']['output'];
};

export type CampaignGiftCodeInput = {
  campaignGiftId: Scalars['UUID']['input'];
  code: Scalars['String']['input'];
};

export type CampaignGiftInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: CampaignGiftType;
};

export enum CampaignGiftType {
  CODE = 'CODE',
  PARCEL = 'PARCEL',
}

export type CampaignListItem = {
  __typename: 'CampaignListItem';
  campaign: Campaign;
  id: Scalars['UUID']['output'];
  statistics?: Maybe<CampaignStatistics>;
};

/** A paginated list of CampaignListItem items. */
export type CampaignListItemPaginator = {
  __typename: 'CampaignListItemPaginator';
  /** A list of CampaignListItem items. */
  data: Array<CampaignListItem>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** A paginated list of Campaign items. */
export type CampaignPaginator = {
  __typename: 'CampaignPaginator';
  /** A list of Campaign items. */
  data: Array<Campaign>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type CampaignReportListItem = {
  __typename: 'CampaignReportListItem';
  campaign: Campaign;
  id: Scalars['UUID']['output'];
  reportable: CampaignReportable;
};

/** A paginated list of CampaignReportListItem items. */
export type CampaignReportListItemPaginator = {
  __typename: 'CampaignReportListItemPaginator';
  /** A list of CampaignReportListItem items. */
  data: Array<CampaignReportListItem>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type CampaignReportable =
  | InstagramPostCampaignReport
  | InstagramReelCampaignReport
  | InstagramStoryCampaignReport
  | TiktokVideoCampaignReport
  | YoutubeVideoCampaignReport;

export enum CampaignRewardType {
  DYNAMIC_MONEY_REWARD = 'DYNAMIC_MONEY_REWARD',
  FIXED_MONEY_REWARD = 'FIXED_MONEY_REWARD',
  NONE = 'NONE',
  VARIABLE_MONEY_REWARD = 'VARIABLE_MONEY_REWARD',
}

export type CampaignStatistics = {
  __typename: 'CampaignStatistics';
  applications: Scalars['Int']['output'];
  applicationsAccepted: Scalars['Int']['output'];
  applicationsToReview: Scalars['Int']['output'];
  contentSubmissions: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  previewsAccepted: Scalars['Int']['output'];
  previewsToReview: Scalars['Int']['output'];
};

export enum CampaignStatus {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
}

export type CampaignTypeInput = {
  channelType: ChannelType;
  instagramCampaign?: InputMaybe<InstagramCampaignInput>;
  tiktokCampaign?: InputMaybe<TiktokCampaignInput>;
  youtubeCampaign?: InputMaybe<YoutubeCampaignInput>;
};

export type CampaignTypeable =
  | InstagramCampaign
  | TiktokCampaign
  | YoutubeCampaign;

export type Category = {
  __typename: 'Category';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type Channel = {
  __typename: 'Channel';
  channelable: Channelable;
  channelableType: ChannelType;
  id: Scalars['UUID']['output'];
  isAccessTokenValid: Scalars['Boolean']['output'];
};

export enum ChannelType {
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
  YOUTUBE = 'YOUTUBE',
}

export type Channelable = InstagramChannel | TiktokChannel | YoutubeChannel;

export type CheckoutSubscriptionForCompanyInput = {
  cancelUrl: Scalars['String']['input'];
  lookupKey: SubscriptionPriceLookupKey;
  priceAmount: Scalars['Int']['input'];
  recurringInterval: RecurringIntervalPrice;
  subscriptionPlanId: Scalars['UUID']['input'];
  successUrl: Scalars['String']['input'];
};

export type Company = {
  __typename: 'Company';
  currentActiveCampaignCount: Scalars['Int']['output'];
  currentBrandCount: Scalars['Int']['output'];
  currentSubscription?: Maybe<SubscriptionObject>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  usedStorageSpace: Scalars['Int']['output'];
};

export type CountryFollowerDemoGraphics = {
  __typename: 'CountryFollowerDemoGraphics';
  country: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  percentage: Scalars['Float']['output'];
};

export type CreateBrandInput = {
  logo: Scalars['Upload']['input'];
  name: Scalars['String']['input'];
};

export type CreateCampaignInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  applyUntil?: InputMaybe<Scalars['DueDate']['input']>;
  brandId: Scalars['UUID']['input'];
  categoryId: Scalars['UUID']['input'];
  description: Scalars['String']['input'];
  fixedMoneyRewardValue?: InputMaybe<Scalars['Int']['input']>;
  gifts: Array<CampaignGiftInput>;
  headerImage: Scalars['Upload']['input'];
  includeContentFileOnSubmission: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  questions: Array<QuestionInput>;
  rewardType: CampaignRewardType;
  taskItems: Array<TaskItemInput>;
  type: CampaignTypeInput;
};

export type CreateCreatorDeliveryAddressTemplateInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
  recipientName: Scalars['String']['input'];
  streetAddressLine1: Scalars['String']['input'];
  streetAddressLine2?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMessageInput = {
  creatorHasCampaignId: Scalars['UUID']['input'];
  text: Scalars['String']['input'];
};

export type Creator = {
  __typename: 'Creator';
  channels: Array<Channel>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isUnreliable: Scalars['Boolean']['output'];
  profilePicture?: Maybe<Download>;
  username: Scalars['String']['output'];
};

export type CreatorAuthenticatable = {
  __typename: 'CreatorAuthenticatable';
  hasSSOLogin: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  locale: Scalars['String']['output'];
  user: Creator;
};

export type CreatorDeliveryAddressTemplate = {
  __typename: 'CreatorDeliveryAddressTemplate';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  postcode: Scalars['String']['output'];
  recipientName: Scalars['String']['output'];
  streetAddressLine1: Scalars['String']['output'];
  streetAddressLine2?: Maybe<Scalars['String']['output']>;
};

export type CreatorHasCampaign = {
  __typename: 'CreatorHasCampaign';
  answers: Array<Answer>;
  applicationAcceptedAt?: Maybe<Scalars['DateTime']['output']>;
  appliedAt: Scalars['DateTime']['output'];
  campaign: Campaign;
  campaignGiftCodes: Array<CampaignGiftCode>;
  channel?: Maybe<Channel>;
  contentSubmittedAt?: Maybe<Scalars['DateTime']['output']>;
  contentUrl?: Maybe<Scalars['String']['output']>;
  creator: Creator;
  deliveryAddress?: Maybe<DeliveryAddress>;
  discontinuedAt?: Maybe<Scalars['DateTime']['output']>;
  discontinuedReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  previewAcceptedAt?: Maybe<Scalars['DateTime']['output']>;
  previewRevisions: Array<PreviewRevision>;
  rewardValue: Scalars['Int']['output'];
  status: CreatorHasCampaignStatus;
};

/** A paginated list of CreatorHasCampaign items. */
export type CreatorHasCampaignPaginator = {
  __typename: 'CreatorHasCampaignPaginator';
  /** A list of CreatorHasCampaign items. */
  data: Array<CreatorHasCampaign>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum CreatorHasCampaignStatus {
  APPLICATION_DECLINED = 'APPLICATION_DECLINED',
  APPLIED = 'APPLIED',
  CAMPAIGN_SUSPENDED = 'CAMPAIGN_SUSPENDED',
  CONTENT_PENDING = 'CONTENT_PENDING',
  CONTENT_SUBMITTED = 'CONTENT_SUBMITTED',
  DISCONTINUED = 'DISCONTINUED',
  PREVIEW_PENDING = 'PREVIEW_PENDING',
  PREVIEW_SUBMITTED = 'PREVIEW_SUBMITTED',
  REPORT_COMPLETED = 'REPORT_COMPLETED',
  REPORT_FAILED = 'REPORT_FAILED',
}

export enum CreatorNotificationChannel {
  EMAIL = 'EMAIL',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
}

export type CreatorNotificationSettings = {
  __typename: 'CreatorNotificationSettings';
  applicationAccepted: Array<CreatorNotificationChannel>;
  applicationDeclined: Array<CreatorNotificationChannel>;
  campaignSuspended: Array<CreatorNotificationChannel>;
  collaborationDiscontinued: Array<CreatorNotificationChannel>;
  id: Scalars['UUID']['output'];
  newCampaignAvailable: Array<CreatorNotificationChannel>;
  newCampaignAvailableCategories: Array<Category>;
  newMessage: Array<CreatorNotificationChannel>;
  previewAccepted: Array<CreatorNotificationChannel>;
  previewRetried: Array<CreatorNotificationChannel>;
  reportFailed: Array<CreatorNotificationChannel>;
  submitContentReminder: Array<CreatorNotificationChannel>;
  submitPreviewReminder: Array<CreatorNotificationChannel>;
  unapplyApplicationReminder: Array<CreatorNotificationChannel>;
};

export enum CreatorNotificationType {
  APPLICATION_ACCEPTED = 'APPLICATION_ACCEPTED',
  APPLICATION_DECLINED = 'APPLICATION_DECLINED',
  CAMPAIGN_SUSPENDED = 'CAMPAIGN_SUSPENDED',
  COLLABORATION_DISCONTINUED = 'COLLABORATION_DISCONTINUED',
  NEW_CAMPAIGN_AVAILABLE = 'NEW_CAMPAIGN_AVAILABLE',
  NEW_MESSAGE = 'NEW_MESSAGE',
  PREVIEW_ACCEPTED = 'PREVIEW_ACCEPTED',
  PREVIEW_RETRIED = 'PREVIEW_RETRIED',
  REPORT_FAILED = 'REPORT_FAILED',
  SUBMIT_CONTENT_REMINDER = 'SUBMIT_CONTENT_REMINDER',
  SUBMIT_PREVIEW_REMINDER = 'SUBMIT_PREVIEW_REMINDER',
  UNAPPLY_APPLICATION_REMINDER = 'UNAPPLY_APPLICATION_REMINDER',
}

/** A paginated list of Creator items. */
export type CreatorPaginator = {
  __typename: 'CreatorPaginator';
  /** A list of Creator items. */
  data: Array<Creator>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type CreatorsOfCampaignInput = {
  campaignId: Scalars['UUID']['input'];
  status: Array<CreatorHasCampaignStatus>;
};

export type DeliveryAddress = {
  __typename: 'DeliveryAddress';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  postcode: Scalars['String']['output'];
  recipientName: Scalars['String']['output'];
  streetAddressLine1: Scalars['String']['output'];
  streetAddressLine2?: Maybe<Scalars['String']['output']>;
};

export type DeliveryAddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
  recipientName: Scalars['String']['input'];
  streetAddressLine1: Scalars['String']['input'];
  streetAddressLine2?: InputMaybe<Scalars['String']['input']>;
};

export type DiscoverCampaignsInput = {
  campaignRewardTypes: Array<CampaignRewardType>;
  categoryIds: Array<Scalars['UUID']['input']>;
  channelTypes: Array<ChannelType>;
  searchName?: InputMaybe<Scalars['String']['input']>;
};

export type Download = {
  __typename: 'Download';
  hash?: Maybe<Scalars['String']['output']>;
  uri: Scalars['String']['output'];
};

export type ForgotCreatorPasswordInput = {
  email: Scalars['String']['input'];
};

export type ForgotManagerPasswordInput = {
  email: Scalars['String']['input'];
};

export type FreeTextTask = {
  __typename: 'FreeTextTask';
  description: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
};

export type FreeTextTaskInput = {
  description: Scalars['String']['input'];
};

export type GenderFollowerDemoGraphics = {
  __typename: 'GenderFollowerDemoGraphics';
  gender: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  percentage: Scalars['Float']['output'];
};

export type HashtagTask = {
  __typename: 'HashtagTask';
  hashtags: Array<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
};

export type HashtagTaskInput = {
  hashtags: Array<Scalars['String']['input']>;
};

export type InstagramCampaign = {
  __typename: 'InstagramCampaign';
  channelType: ChannelType;
  id: Scalars['UUID']['output'];
  instagramContentType: InstagramContentType;
};

export type InstagramCampaignInput = {
  contentType: InstagramContentType;
};

export type InstagramChannel = {
  __typename: 'InstagramChannel';
  ageFollowerDemographics: Array<AgeFollowerDemoGraphics>;
  averageEngagementRate?: Maybe<Scalars['Float']['output']>;
  averageReach?: Maybe<Scalars['Int']['output']>;
  countryFollowerDemographics: Array<CountryFollowerDemoGraphics>;
  followersCount: Scalars['Int']['output'];
  genderFollowerDemographics: Array<GenderFollowerDemoGraphics>;
  id: Scalars['UUID']['output'];
  mediaCount: Scalars['Int']['output'];
  url: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export enum InstagramContentType {
  POST = 'POST',
  REEL = 'REEL',
  STORY = 'STORY',
}

export type InstagramMedia = {
  __typename: 'InstagramMedia';
  caption?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  mediaProductType: InstagramMediaProductType;
  mediaType: InstagramMediaType;
  mediaUrl?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
};

export enum InstagramMediaProductType {
  AD = 'AD',
  FEED = 'FEED',
  REELS = 'REELS',
  STORY = 'STORY',
}

export enum InstagramMediaType {
  CAROUSEL_ALBUM = 'CAROUSEL_ALBUM',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export type InstagramPostCampaignReport = {
  __typename: 'InstagramPostCampaignReport';
  completedCount: Scalars['Int']['output'];
  cpv?: Maybe<Scalars['Float']['output']>;
  creatorHasCampaignReports: Array<InstagramPostCreatorHasCampaignReport>;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  followerCount: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  rewardValue: Scalars['Int']['output'];
  saves: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
};

export type InstagramPostCreatorHasCampaignReport = {
  __typename: 'InstagramPostCreatorHasCampaignReport';
  cpv?: Maybe<Scalars['Float']['output']>;
  creatorHasCampaign: CreatorHasCampaign;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  followerCount: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  lastFetchedAt?: Maybe<Scalars['DateTime']['output']>;
  rewardValue: Scalars['Int']['output'];
  saves: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
};

export type InstagramReelCampaignReport = {
  __typename: 'InstagramReelCampaignReport';
  comments: Scalars['Int']['output'];
  completedCount: Scalars['Int']['output'];
  cpv?: Maybe<Scalars['Float']['output']>;
  creatorHasCampaignReports: Array<InstagramReelCreatorHasCampaignReport>;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  followerCount: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  likes: Scalars['Int']['output'];
  reach: Scalars['Int']['output'];
  rewardValue: Scalars['Int']['output'];
  shares: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
};

export type InstagramReelCreatorHasCampaignReport = {
  __typename: 'InstagramReelCreatorHasCampaignReport';
  comments: Scalars['Int']['output'];
  cpv?: Maybe<Scalars['Float']['output']>;
  creatorHasCampaign: CreatorHasCampaign;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  followerCount: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  lastFetchedAt?: Maybe<Scalars['DateTime']['output']>;
  likes: Scalars['Int']['output'];
  reach: Scalars['Int']['output'];
  rewardValue: Scalars['Int']['output'];
  shares: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
};

export type InstagramStoryCampaignReport = {
  __typename: 'InstagramStoryCampaignReport';
  completedCount: Scalars['Int']['output'];
  cpv?: Maybe<Scalars['Float']['output']>;
  creatorHasCampaignReports: Array<InstagramStoryCreatorHasCampaignReport>;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  followerCount: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  rewardValue: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
};

export type InstagramStoryCreatorHasCampaignReport = {
  __typename: 'InstagramStoryCreatorHasCampaignReport';
  cpv?: Maybe<Scalars['Float']['output']>;
  creatorHasCampaign: CreatorHasCampaign;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  followerCount: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  lastFetchedAt?: Maybe<Scalars['DateTime']['output']>;
  rewardValue: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
};

export type LoginCreatorInput = {
  deviceName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginManagerInput = {
  deviceName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Manager = {
  __typename: 'Manager';
  email: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
};

export type ManagerAuthenticatable = {
  __typename: 'ManagerAuthenticatable';
  id: Scalars['UUID']['output'];
  isAdmin: Scalars['String']['output'];
  locale: Scalars['String']['output'];
  user: Manager;
};

/** A paginated list of Manager items. */
export type ManagerPaginator = {
  __typename: 'ManagerPaginator';
  /** A list of Manager items. */
  data: Array<Manager>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type Message = {
  __typename: 'Message';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  sender: MessageSender;
  text: Scalars['String']['output'];
};

/** A paginated list of Message items. */
export type MessagePaginator = {
  __typename: 'MessagePaginator';
  /** A list of Message items. */
  data: Array<Message>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum MessageSender {
  BRAND = 'BRAND',
  CREATOR = 'CREATOR',
}

export type Mutation = {
  __typename: 'Mutation';
  acceptCampaignApplication: CreatorHasCampaign;
  acceptPreview: CreatorHasCampaign;
  addInstagramChannel: Creator;
  addTiktokChannel: Creator;
  addYoutubeChannel: Creator;
  applyForCampaign: CreatorHasCampaign;
  checkoutSubscriptionForCompany: Scalars['String']['output'];
  completeCampaign: Campaign;
  createBrand: Brand;
  createCampaign: Campaign;
  createCreatorDeliveryAddressTemplate: CreatorDeliveryAddressTemplate;
  createMessage: Message;
  deactivateCreator: Scalars['String']['output'];
  declineCampaignApplication: CreatorHasCampaign;
  deleteCampaign: Scalars['String']['output'];
  deleteChannel: Creator;
  deleteCreatorDeliveryAddressTemplate: Scalars['String']['output'];
  deleteManager: Scalars['String']['output'];
  forgotCreatorPassword: Scalars['String']['output'];
  forgotManagerPassword: Scalars['String']['output'];
  impersonateCreator: Scalars['String']['output'];
  impersonateManager: Scalars['String']['output'];
  loginCreator: Scalars['String']['output'];
  loginManager: Scalars['String']['output'];
  logoutCreator: Scalars['String']['output'];
  logoutManager: Scalars['String']['output'];
  publishCampaign: Campaign;
  recoverCreatorPassword: Scalars['String']['output'];
  recoverManagerPassword: Scalars['String']['output'];
  registerCompany: Company;
  registerCreator: CreatorAuthenticatable;
  requestVerificationOfNewCreatorEmail: Scalars['String']['output'];
  requestVerificationOfNewManagerEmail: Scalars['String']['output'];
  resendCreatorVerificationEmail: Scalars['String']['output'];
  resendManagerVerificationEmail: Scalars['String']['output'];
  retryPreview: CreatorHasCampaign;
  setCreatorExpoPushToken: Scalars['String']['output'];
  socialLoginCreator: Scalars['String']['output'];
  socialRegisterCreator: Scalars['String']['output'];
  submitContent: CreatorHasCampaign;
  submitPreview: CreatorHasCampaign;
  unapplyFromCampaign: Scalars['String']['output'];
  updateAccessTokenForInstagramChannel: Creator;
  updateAccessTokenForTiktokChannel: Creator;
  updateAccessTokenForYoutubeChannel: Creator;
  updateApplyUntil: Campaign;
  updateBrand: Brand;
  updateBrandLogo: Brand;
  updateCampaign: Campaign;
  updateCampaignHeaderImage: Campaign;
  updateCreatorDeliveryAddressTemplate: CreatorDeliveryAddressTemplate;
  updateCreatorLocale: CreatorAuthenticatable;
  updateCreatorNotificationSetting: CreatorNotificationSettings;
  updateCreatorPassword: Scalars['String']['output'];
  updateCreatorProfile: Creator;
  updateManagerLocale: ManagerAuthenticatable;
  updateManagerPassword: Scalars['String']['output'];
  updateNewCampaignAvailableNotificationCategories: CreatorNotificationSettings;
  updateRewardValue: CreatorHasCampaign;
  uploadCreatorProfilePicture: Creator;
  verifyAndUpdateCreatorEmail: Creator;
  verifyAndUpdateManagerEmail: Manager;
  verifyCreatorEmail: Scalars['String']['output'];
  verifyManagerEmail: Scalars['String']['output'];
};

export type MutationAcceptCampaignApplicationArgs = {
  input: AcceptCampaignApplicationInput;
};

export type MutationAcceptPreviewArgs = {
  creatorHasCampaignId: Scalars['UUID']['input'];
};

export type MutationAddInstagramChannelArgs = {
  input: AddInstagramChannelInput;
};

export type MutationAddTiktokChannelArgs = {
  input: AddTiktokChannelInput;
};

export type MutationAddYoutubeChannelArgs = {
  input: AddYoutubeChannelInput;
};

export type MutationApplyForCampaignArgs = {
  input: ApplyForCampaignInput;
};

export type MutationCheckoutSubscriptionForCompanyArgs = {
  input: CheckoutSubscriptionForCompanyInput;
};

export type MutationCompleteCampaignArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationCreateBrandArgs = {
  input: CreateBrandInput;
};

export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput;
};

export type MutationCreateCreatorDeliveryAddressTemplateArgs = {
  input: CreateCreatorDeliveryAddressTemplateInput;
};

export type MutationCreateMessageArgs = {
  input: CreateMessageInput;
};

export type MutationDeclineCampaignApplicationArgs = {
  creatorHasCampaignId: Scalars['UUID']['input'];
};

export type MutationDeleteCampaignArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationDeleteChannelArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationDeleteCreatorDeliveryAddressTemplateArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationForgotCreatorPasswordArgs = {
  input: ForgotCreatorPasswordInput;
};

export type MutationForgotManagerPasswordArgs = {
  input: ForgotManagerPasswordInput;
};

export type MutationImpersonateCreatorArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationImpersonateManagerArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationLoginCreatorArgs = {
  input: LoginCreatorInput;
};

export type MutationLoginManagerArgs = {
  input: LoginManagerInput;
};

export type MutationPublishCampaignArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationRecoverCreatorPasswordArgs = {
  input?: InputMaybe<RecoverCreatorPasswordInput>;
};

export type MutationRecoverManagerPasswordArgs = {
  input?: InputMaybe<RecoverManagerPasswordInput>;
};

export type MutationRegisterCompanyArgs = {
  input: RegisterCompanyInput;
};

export type MutationRegisterCreatorArgs = {
  input: RegisterCreatorInput;
};

export type MutationRequestVerificationOfNewCreatorEmailArgs = {
  input: RequestVerificationOfNewCreatorEmailInput;
};

export type MutationRequestVerificationOfNewManagerEmailArgs = {
  input: RequestVerificationOfNewManagerEmailInput;
};

export type MutationResendCreatorVerificationEmailArgs = {
  input: ResendCreatorVerificationEmailInput;
};

export type MutationResendManagerVerificationEmailArgs = {
  input: ResendManagerVerificationEmailInput;
};

export type MutationRetryPreviewArgs = {
  input: RetryPreviewInput;
};

export type MutationSetCreatorExpoPushTokenArgs = {
  input: SetCreatorExpoPushTokenInput;
};

export type MutationSocialLoginCreatorArgs = {
  input: SocialLoginCreatorInput;
};

export type MutationSocialRegisterCreatorArgs = {
  input: SocialRegisterCreatorInput;
};

export type MutationSubmitContentArgs = {
  input: SubmitContentInput;
};

export type MutationSubmitPreviewArgs = {
  input: SubmitPreviewInput;
};

export type MutationUnapplyFromCampaignArgs = {
  creatorHasCampaignId: Scalars['UUID']['input'];
};

export type MutationUpdateAccessTokenForInstagramChannelArgs = {
  input: UpdateAccessTokenForInstagramChannelInput;
};

export type MutationUpdateAccessTokenForTiktokChannelArgs = {
  input: UpdateAccessTokenForTiktokChannelInput;
};

export type MutationUpdateAccessTokenForYoutubeChannelArgs = {
  input: UpdateAccessTokenForYoutubeChannelInput;
};

export type MutationUpdateApplyUntilArgs = {
  input: UpdateApplyUntilInput;
};

export type MutationUpdateBrandArgs = {
  input: UpdateBrandInput;
};

export type MutationUpdateBrandLogoArgs = {
  input: UpdateBrandLogoInput;
};

export type MutationUpdateCampaignArgs = {
  input: UpdateCampaignInput;
};

export type MutationUpdateCampaignHeaderImageArgs = {
  input: UpdateCampaignHeaderImageInput;
};

export type MutationUpdateCreatorDeliveryAddressTemplateArgs = {
  input: UpdateCreatorDeliveryAddressTemplateInput;
};

export type MutationUpdateCreatorLocaleArgs = {
  input: UpdateCreatorLocaleInput;
};

export type MutationUpdateCreatorNotificationSettingArgs = {
  input: UpdateCreatorNotificationSettingInput;
};

export type MutationUpdateCreatorPasswordArgs = {
  input: UpdateCreatorPasswordInput;
};

export type MutationUpdateCreatorProfileArgs = {
  input: UpdateCreatorProfileInput;
};

export type MutationUpdateManagerLocaleArgs = {
  input: UpdateManagerLocaleInput;
};

export type MutationUpdateManagerPasswordArgs = {
  input: UpdateManagerPasswordInput;
};

export type MutationUpdateNewCampaignAvailableNotificationCategoriesArgs = {
  input: UpdateNewCampaignAvailableNotificationCategoriesInput;
};

export type MutationUpdateRewardValueArgs = {
  input: UpdateRewardValueInput;
};

export type MutationUploadCreatorProfilePictureArgs = {
  input: UploadCreatorProfilePictureInput;
};

export type MutationVerifyAndUpdateCreatorEmailArgs = {
  input: VerifyCreatorEmailInput;
};

export type MutationVerifyAndUpdateManagerEmailArgs = {
  input: VerifyManagerEmailInput;
};

export type MutationVerifyCreatorEmailArgs = {
  input: VerifyCreatorEmailInput;
};

export type MutationVerifyManagerEmailArgs = {
  input: VerifyManagerEmailInput;
};

export type MyCampaignReportsInput = {
  brandIds: Array<Scalars['UUID']['input']>;
};

export type MyCampaignsInput = {
  brandIds: Array<Scalars['UUID']['input']>;
  status: Array<CampaignStatus>;
};

export type MyCreatorHasCampaignsInput = {
  searchName?: InputMaybe<Scalars['String']['input']>;
  status: Array<CreatorHasCampaignStatus>;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String']['input'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  COUNT = 'COUNT',
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  AVG = 'AVG',
  /** Amount of items. */
  COUNT = 'COUNT',
  /** Maximum. */
  MAX = 'MAX',
  /** Minimum. */
  MIN = 'MIN',
  /** Sum. */
  SUM = 'SUM',
}

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int']['output'];
  /** Index of the current page. */
  currentPage: Scalars['Int']['output'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']['output']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean']['output'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']['output']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int']['output'];
  /** Number of items per page. */
  perPage: Scalars['Int']['output'];
  /** Number of total available items. */
  total: Scalars['Int']['output'];
};

export type PreviewRevision = {
  __typename: 'PreviewRevision';
  caption: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  retriedAt?: Maybe<Scalars['DateTime']['output']>;
  retriedReason?: Maybe<Scalars['String']['output']>;
  revisionNumber: Scalars['Int']['output'];
  submittedAt: Scalars['DateTime']['output'];
};

export type Query = {
  __typename: 'Query';
  brand: Brand;
  campaign: Campaign;
  campaignReportForCampaign?: Maybe<CampaignReportable>;
  campaignStatisticsForCampaign?: Maybe<CampaignStatistics>;
  categories: Array<Category>;
  creatorHasCampaign: CreatorHasCampaign;
  creatorHasCampaignContentFile: Download;
  creators: CreatorPaginator;
  creatorsOfCampaign: CreatorHasCampaignPaginator;
  discoverCampaigns: CampaignPaginator;
  dynamicMoneyRewardValue: Scalars['Int']['output'];
  latestInstagramMedia: Array<InstagramMedia>;
  latestInstagramStories: Array<InstagramMedia>;
  latestTiktokVideos: Array<TiktokVideo>;
  latestYoutubeVideos: Array<YoutubeVideo>;
  managers: ManagerPaginator;
  messages: MessagePaginator;
  myBrands: Array<Brand>;
  myCampaignReports: CampaignReportListItemPaginator;
  myCampaigns: CampaignListItemPaginator;
  myCompany: Company;
  myCreatorDeliveryAddressTemplates: Array<CreatorDeliveryAddressTemplate>;
  myCreatorHasCampaignForCampaign?: Maybe<CreatorHasCampaign>;
  myCreatorHasCampaigns: CreatorHasCampaignPaginator;
  myCreatorNotificationSettings: CreatorNotificationSettings;
  previewFile: Download;
  priceForCreatorHasCampaign: Scalars['Int']['output'];
  stripeCustomerPortalUrl: Scalars['String']['output'];
  subscriptionPlans: Array<SubscriptionPlan>;
  whoAmI: Authenticatable;
};

export type QueryBrandArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryCampaignArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryCampaignReportForCampaignArgs = {
  campaignId: Scalars['UUID']['input'];
};

export type QueryCampaignStatisticsForCampaignArgs = {
  campaignId: Scalars['UUID']['input'];
};

export type QueryCreatorHasCampaignArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryCreatorHasCampaignContentFileArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryCreatorsArgs = {
  first?: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryCreatorsOfCampaignArgs = {
  first?: Scalars['Int']['input'];
  input: CreatorsOfCampaignInput;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryDiscoverCampaignsArgs = {
  first?: Scalars['Int']['input'];
  input: DiscoverCampaignsInput;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryDynamicMoneyRewardValueArgs = {
  campaignId: Scalars['UUID']['input'];
};

export type QueryLatestInstagramMediaArgs = {
  instagramChannelId: Scalars['UUID']['input'];
};

export type QueryLatestInstagramStoriesArgs = {
  instagramChannelId: Scalars['UUID']['input'];
};

export type QueryLatestTiktokVideosArgs = {
  tiktokChannelId: Scalars['UUID']['input'];
};

export type QueryLatestYoutubeVideosArgs = {
  youtubeChannelId: Scalars['UUID']['input'];
};

export type QueryManagersArgs = {
  first?: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryMessagesArgs = {
  creatorHasCampaignId: Scalars['UUID']['input'];
  first?: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryMyCampaignReportsArgs = {
  first?: Scalars['Int']['input'];
  input: MyCampaignReportsInput;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryMyCampaignsArgs = {
  first?: Scalars['Int']['input'];
  input: MyCampaignsInput;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryMyCreatorHasCampaignForCampaignArgs = {
  campaignId: Scalars['UUID']['input'];
};

export type QueryMyCreatorHasCampaignsArgs = {
  first?: Scalars['Int']['input'];
  input: MyCreatorHasCampaignsInput;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryPreviewFileArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryPriceForCreatorHasCampaignArgs = {
  creatorHasCampaignId: Scalars['UUID']['input'];
};

export type QueryStripeCustomerPortalUrlArgs = {
  input: StripeCustomerPortalUrlInput;
};

export type Question = {
  __typename: 'Question';
  id: Scalars['UUID']['output'];
  question: Scalars['String']['output'];
};

export type QuestionInput = {
  question: Scalars['String']['input'];
};

export type RecoverCreatorPasswordInput = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type RecoverManagerPasswordInput = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export enum RecurringIntervalPrice {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export type RegisterCompanyInput = {
  manager: RegisterManagerInput;
  name: Scalars['String']['input'];
};

export type RegisterCreatorInput = {
  acceptedNewsletter: Scalars['Boolean']['input'];
  confirmedPrivacyTerms: Scalars['Boolean']['input'];
  confirmedTermsOfService: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  olderThanSixteen: Scalars['Boolean']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type RegisterManagerInput = {
  acceptedNewsletter: Scalars['Boolean']['input'];
  confirmedPrivacyTerms: Scalars['Boolean']['input'];
  confirmedTermsOfService: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type RequestVerificationOfNewCreatorEmailInput = {
  email: Scalars['String']['input'];
};

export type RequestVerificationOfNewManagerEmailInput = {
  email: Scalars['String']['input'];
};

export type ResendCreatorVerificationEmailInput = {
  email: Scalars['String']['input'];
};

export type ResendManagerVerificationEmailInput = {
  email: Scalars['String']['input'];
};

export type RetryPreviewInput = {
  creatorHasCampaignId: Scalars['UUID']['input'];
  reason: Scalars['String']['input'];
};

export type SetCreatorExpoPushTokenInput = {
  token: Scalars['String']['input'];
};

export type SocialLoginCreatorInput = {
  provider: SocialLoginProvider;
  token: Scalars['String']['input'];
};

export enum SocialLoginProvider {
  APPLE = 'APPLE',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
}

export type SocialRegisterCreatorInput = {
  acceptedNewsletter: Scalars['Boolean']['input'];
  confirmedPrivacyTerms: Scalars['Boolean']['input'];
  confirmedTermsOfService: Scalars['Boolean']['input'];
  locale: Scalars['String']['input'];
  olderThanSixteen: Scalars['Boolean']['input'];
  provider: SocialLoginProvider;
  token: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  ASC = 'ASC',
  /** Sort records in descending order. */
  DESC = 'DESC',
}

export type StripeCustomerPortalUrlInput = {
  returnUrl: Scalars['String']['input'];
};

export type SubmitContentInput = {
  contentFile?: InputMaybe<Scalars['Upload']['input']>;
  contentId: Scalars['String']['input'];
  creatorHasCampaignId: Scalars['UUID']['input'];
};

export type SubmitPreviewInput = {
  caption: Scalars['String']['input'];
  creatorHasCampaignId: Scalars['UUID']['input'];
  previewFile: Scalars['Upload']['input'];
};

export type SubscriptionObject = {
  __typename: 'SubscriptionObject';
  id: Scalars['UUID']['output'];
  status: Scalars['String']['output'];
  subscriptionPlan?: Maybe<SubscriptionPlan>;
};

export type SubscriptionPlan = {
  __typename: 'SubscriptionPlan';
  bookingFeePercentage: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  maxBrandCount?: Maybe<Scalars['Int']['output']>;
  maxCampaignCount?: Maybe<Scalars['Int']['output']>;
  minBookingFee: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  prices: Array<SubscriptionPrice>;
  storageSpaceLimit: Scalars['Int']['output'];
};

export type SubscriptionPrice = {
  __typename: 'SubscriptionPrice';
  amount: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  lookupKey: SubscriptionPriceLookupKey;
  recurringInterval: RecurringIntervalPrice;
};

export enum SubscriptionPriceLookupKey {
  LARGE_MONTHLY = 'LARGE_MONTHLY',
  LARGE_YEARLY = 'LARGE_YEARLY',
  MEDIUM_MONTHLY = 'MEDIUM_MONTHLY',
  MEDIUM_YEARLY = 'MEDIUM_YEARLY',
  SMALL_MONTHLY = 'SMALL_MONTHLY',
  SMALL_YEARLY = 'SMALL_YEARLY',
}

export type TaskItem = {
  __typename: 'TaskItem';
  id: Scalars['UUID']['output'];
  taskable: Taskable;
  type: TaskItemType;
};

export type TaskItemInput = {
  freeTextTask?: InputMaybe<FreeTextTaskInput>;
  hashtagTask?: InputMaybe<HashtagTaskInput>;
  type: TaskItemType;
  websiteTask?: InputMaybe<WebsiteTaskInput>;
};

export enum TaskItemType {
  FREE_TEXT = 'FREE_TEXT',
  HASHTAG = 'HASHTAG',
  WEBSITE = 'WEBSITE',
}

export type Taskable = FreeTextTask | HashtagTask | WebsiteTask;

export type TiktokCampaign = {
  __typename: 'TiktokCampaign';
  channelType: ChannelType;
  id: Scalars['UUID']['output'];
  tiktokContentType: TiktokContentType;
};

export type TiktokCampaignInput = {
  contentType: TiktokContentType;
};

export type TiktokChannel = {
  __typename: 'TiktokChannel';
  averageEngagementRate?: Maybe<Scalars['Float']['output']>;
  averageReach?: Maybe<Scalars['Int']['output']>;
  displayName: Scalars['String']['output'];
  followerCount: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  url: Scalars['String']['output'];
  videoCount: Scalars['Int']['output'];
};

export enum TiktokContentType {
  VIDEO = 'VIDEO',
}

export type TiktokVideo = {
  __typename: 'TiktokVideo';
  coverImageUrl: Scalars['String']['output'];
  id: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type TiktokVideoCampaignReport = {
  __typename: 'TiktokVideoCampaignReport';
  comments: Scalars['Int']['output'];
  completedCount: Scalars['Int']['output'];
  cpv?: Maybe<Scalars['Float']['output']>;
  creatorHasCampaignReports: Array<TiktokVideoCreatorHasCampaignReport>;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  followerCount: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  likes: Scalars['Int']['output'];
  rewardValue: Scalars['Int']['output'];
  shares: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
};

export type TiktokVideoCreatorHasCampaignReport = {
  __typename: 'TiktokVideoCreatorHasCampaignReport';
  comments: Scalars['Int']['output'];
  cpv?: Maybe<Scalars['Float']['output']>;
  creatorHasCampaign: CreatorHasCampaign;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  followerCount: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  lastFetchedAt?: Maybe<Scalars['DateTime']['output']>;
  likes: Scalars['Int']['output'];
  rewardValue: Scalars['Int']['output'];
  shares: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  ONLY = 'ONLY',
  /** Return both trashed and non-trashed results. */
  WITH = 'WITH',
  /** Only return non-trashed results. */
  WITHOUT = 'WITHOUT',
}

export type UpdateAccessTokenForInstagramChannelInput = {
  accessToken: Scalars['String']['input'];
  instagramChannelId: Scalars['UUID']['input'];
};

export type UpdateAccessTokenForTiktokChannelInput = {
  code: Scalars['String']['input'];
  tiktokChannelId: Scalars['UUID']['input'];
};

export type UpdateAccessTokenForYoutubeChannelInput = {
  code: Scalars['String']['input'];
  youtubeChannelId: Scalars['UUID']['input'];
};

export type UpdateApplyUntilInput = {
  applyUntil?: InputMaybe<Scalars['DueDate']['input']>;
  id: Scalars['UUID']['input'];
};

export type UpdateBrandInput = {
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateBrandLogoInput = {
  id: Scalars['UUID']['input'];
  logo: Scalars['Upload']['input'];
};

export type UpdateCampaignHeaderImageInput = {
  headerImage: Scalars['Upload']['input'];
  id: Scalars['UUID']['input'];
};

export type UpdateCampaignInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  applyUntil?: InputMaybe<Scalars['DueDate']['input']>;
  brandId: Scalars['UUID']['input'];
  categoryId: Scalars['UUID']['input'];
  description: Scalars['String']['input'];
  fixedMoneyRewardValue?: InputMaybe<Scalars['Int']['input']>;
  gifts: Array<CampaignGiftInput>;
  id: Scalars['UUID']['input'];
  includeContentFileOnSubmission: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  questions: Array<QuestionInput>;
  rewardType: CampaignRewardType;
  taskItems: Array<TaskItemInput>;
  type: CampaignTypeInput;
};

export type UpdateCreatorDeliveryAddressTemplateInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
  postcode: Scalars['String']['input'];
  recipientName: Scalars['String']['input'];
  streetAddressLine1: Scalars['String']['input'];
  streetAddressLine2?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCreatorLocaleInput = {
  locale: Scalars['String']['input'];
};

export type UpdateCreatorNotificationSettingInput = {
  channels: Array<CreatorNotificationChannel>;
  type: CreatorNotificationType;
};

export type UpdateCreatorPasswordInput = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type UpdateCreatorProfileInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type UpdateManagerLocaleInput = {
  locale: Scalars['String']['input'];
};

export type UpdateManagerPasswordInput = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type UpdateNewCampaignAvailableNotificationCategoriesInput = {
  categoryIds: Array<Scalars['UUID']['input']>;
};

export type UpdateRewardValueInput = {
  creatorHasCampaignId: Scalars['UUID']['input'];
  rewardValue: Scalars['Int']['input'];
};

export type UploadCreatorProfilePictureInput = {
  profilePicture: Scalars['Upload']['input'];
};

export type VerifyCreatorEmailInput = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type VerifyManagerEmailInput = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type WebsiteTask = {
  __typename: 'WebsiteTask';
  id: Scalars['UUID']['output'];
  url: Scalars['String']['output'];
};

export type WebsiteTaskInput = {
  url: Scalars['String']['input'];
};

export type YoutubeCampaign = {
  __typename: 'YoutubeCampaign';
  channelType: ChannelType;
  id: Scalars['UUID']['output'];
  youtubeContentType: YoutubeContentType;
};

export type YoutubeCampaignInput = {
  contentType: YoutubeContentType;
};

export type YoutubeChannel = {
  __typename: 'YoutubeChannel';
  averageEngagementRate?: Maybe<Scalars['Float']['output']>;
  averageReach?: Maybe<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  subscriberCount: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
  videoCount: Scalars['Int']['output'];
};

export enum YoutubeContentType {
  VIDEO = 'VIDEO',
}

export type YoutubeVideo = {
  __typename: 'YoutubeVideo';
  coverImageUrl: Scalars['String']['output'];
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type YoutubeVideoCampaignReport = {
  __typename: 'YoutubeVideoCampaignReport';
  comments: Scalars['Int']['output'];
  completedCount: Scalars['Int']['output'];
  cpv?: Maybe<Scalars['Float']['output']>;
  creatorHasCampaignReports: Array<YoutubeVideoCreatorHasCampaignReport>;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  favorites: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  likes: Scalars['Int']['output'];
  rewardValue: Scalars['Int']['output'];
  subscriberCount: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
};

export type YoutubeVideoCreatorHasCampaignReport = {
  __typename: 'YoutubeVideoCreatorHasCampaignReport';
  comments: Scalars['Int']['output'];
  cpv?: Maybe<Scalars['Float']['output']>;
  creatorHasCampaign: CreatorHasCampaign;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  favorites: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  lastFetchedAt?: Maybe<Scalars['DateTime']['output']>;
  likes: Scalars['Int']['output'];
  rewardValue: Scalars['Int']['output'];
  subscriberCount: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
};

export type MyBrandsQueryVariables = Exact<{ [key: string]: never }>;

export type MyBrandsQuery = {
  __typename: 'Query';
  myBrands: Array<{
    __typename: 'Brand';
    id: string;
    name: string;
    logo: { __typename: 'Download'; uri: string; hash?: string | null };
  }>;
};

export type BrandQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type BrandQuery = {
  __typename: 'Query';
  brand: {
    __typename: 'Brand';
    id: string;
    name: string;
    logo: { __typename: 'Download'; uri: string; hash?: string | null };
  };
};

export type CreateBrandMutationVariables = Exact<{
  input: CreateBrandInput;
}>;

export type CreateBrandMutation = {
  __typename: 'Mutation';
  createBrand: {
    __typename: 'Brand';
    id: string;
    name: string;
    logo: { __typename: 'Download'; uri: string; hash?: string | null };
  };
};

export type UpdateBrandMutationVariables = Exact<{
  input: UpdateBrandInput;
}>;

export type UpdateBrandMutation = {
  __typename: 'Mutation';
  updateBrand: {
    __typename: 'Brand';
    id: string;
    name: string;
    logo: { __typename: 'Download'; uri: string; hash?: string | null };
  };
};

export type UpdateBrandLogoMutationVariables = Exact<{
  input: UpdateBrandLogoInput;
}>;

export type UpdateBrandLogoMutation = {
  __typename: 'Mutation';
  updateBrandLogo: {
    __typename: 'Brand';
    id: string;
    name: string;
    logo: { __typename: 'Download'; uri: string; hash?: string | null };
  };
};

export type CampaignQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type CampaignQuery = {
  __typename: 'Query';
  campaign: {
    __typename: 'Campaign';
    id: string;
    name: string;
    description: string;
    status: CampaignStatus;
    rewardType: CampaignRewardType;
    fixedMoneyRewardValue?: number | null;
    applyUntil?: string | null;
    address?: string | null;
    includeContentFileOnSubmission: boolean;
    taskItems: Array<{
      __typename: 'TaskItem';
      id: string;
      type: TaskItemType;
      taskable:
        | { __typename: 'FreeTextTask'; id: string; description: string }
        | { __typename: 'HashtagTask'; id: string; hashtags: Array<string> }
        | { __typename: 'WebsiteTask'; id: string; url: string };
    }>;
    headerImage: { __typename: 'Download'; uri: string; hash?: string | null };
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      logo: { __typename: 'Download'; uri: string; hash?: string | null };
    };
    category: { __typename: 'Category'; id: string; name: string };
    gifts: Array<{
      __typename: 'CampaignGift';
      id: string;
      type: CampaignGiftType;
      name: string;
      description: string;
    }>;
    questions: Array<{ __typename: 'Question'; id: string; question: string }>;
    typeable:
      | {
          __typename: 'InstagramCampaign';
          id: string;
          channelType: ChannelType;
          instagramContentType: InstagramContentType;
        }
      | {
          __typename: 'TiktokCampaign';
          id: string;
          channelType: ChannelType;
          tiktokContentType: TiktokContentType;
        }
      | {
          __typename: 'YoutubeCampaign';
          id: string;
          channelType: ChannelType;
          youtubeContentType: YoutubeContentType;
        };
  };
};

export type DynamicMoneyRewardValueQueryVariables = Exact<{
  campaignId: Scalars['UUID']['input'];
}>;

export type DynamicMoneyRewardValueQuery = {
  __typename: 'Query';
  dynamicMoneyRewardValue: number;
};

export type CampaignYouAreApplyingForQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type CampaignYouAreApplyingForQuery = {
  __typename: 'Query';
  campaign: {
    __typename: 'Campaign';
    id: string;
    name: string;
    rewardType: CampaignRewardType;
    fixedMoneyRewardValue?: number | null;
    headerImage: { __typename: 'Download'; hash?: string | null; uri: string };
    questions: Array<{ __typename: 'Question'; id: string; question: string }>;
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      logo: { __typename: 'Download'; uri: string; hash?: string | null };
    };
    gifts: Array<{
      __typename: 'CampaignGift';
      id: string;
      type: CampaignGiftType;
    }>;
    typeable:
      | {
          __typename: 'InstagramCampaign';
          id: string;
          channelType: ChannelType;
          instagramContentType: InstagramContentType;
        }
      | {
          __typename: 'TiktokCampaign';
          id: string;
          channelType: ChannelType;
          tiktokContentType: TiktokContentType;
        }
      | {
          __typename: 'YoutubeCampaign';
          id: string;
          channelType: ChannelType;
          youtubeContentType: YoutubeContentType;
        };
  };
};

export type CampaignGiftQueryVariables = Exact<{
  campaignId: Scalars['UUID']['input'];
}>;

export type CampaignGiftQuery = {
  __typename: 'Query';
  campaign: {
    __typename: 'Campaign';
    id: string;
    gifts: Array<{
      __typename: 'CampaignGift';
      id: string;
      type: CampaignGiftType;
      name: string;
      description: string;
    }>;
  };
};

export type DiscoverCampaignsQueryVariables = Exact<{
  input: DiscoverCampaignsInput;
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type DiscoverCampaignsQuery = {
  __typename: 'Query';
  discoverCampaigns: {
    __typename: 'CampaignPaginator';
    data: Array<{
      __typename: 'Campaign';
      id: string;
      name: string;
      headerImage: {
        __typename: 'Download';
        uri: string;
        hash?: string | null;
      };
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
        logo: { __typename: 'Download'; uri: string; hash?: string | null };
      };
      category: { __typename: 'Category'; id: string; name: string };
      typeable:
        | {
            __typename: 'InstagramCampaign';
            id: string;
            channelType: ChannelType;
            instagramContentType: InstagramContentType;
          }
        | {
            __typename: 'TiktokCampaign';
            id: string;
            channelType: ChannelType;
            tiktokContentType: TiktokContentType;
          }
        | {
            __typename: 'YoutubeCampaign';
            id: string;
            channelType: ChannelType;
            youtubeContentType: YoutubeContentType;
          };
    }>;
    paginatorInfo: {
      __typename: 'PaginatorInfo';
      hasMorePages: boolean;
      currentPage: number;
    };
  };
};

export type CampaignBriefingQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type CampaignBriefingQuery = {
  __typename: 'Query';
  campaign: {
    __typename: 'Campaign';
    id: string;
    name: string;
    description: string;
    status: CampaignStatus;
    rewardType: CampaignRewardType;
    fixedMoneyRewardValue?: number | null;
    applyUntil?: string | null;
    address?: string | null;
    includeContentFileOnSubmission: boolean;
    taskItems: Array<{
      __typename: 'TaskItem';
      id: string;
      type: TaskItemType;
      taskable:
        | { __typename: 'FreeTextTask'; id: string; description: string }
        | { __typename: 'HashtagTask'; id: string; hashtags: Array<string> }
        | { __typename: 'WebsiteTask'; id: string; url: string };
    }>;
    headerImage: { __typename: 'Download'; uri: string; hash?: string | null };
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      logo: { __typename: 'Download'; uri: string; hash?: string | null };
    };
    category: { __typename: 'Category'; id: string; name: string };
    gifts: Array<{
      __typename: 'CampaignGift';
      id: string;
      type: CampaignGiftType;
      name: string;
      description: string;
    }>;
    questions: Array<{ __typename: 'Question'; id: string; question: string }>;
    typeable:
      | {
          __typename: 'InstagramCampaign';
          id: string;
          channelType: ChannelType;
          instagramContentType: InstagramContentType;
        }
      | {
          __typename: 'TiktokCampaign';
          id: string;
          channelType: ChannelType;
          tiktokContentType: TiktokContentType;
        }
      | {
          __typename: 'YoutubeCampaign';
          id: string;
          channelType: ChannelType;
          youtubeContentType: YoutubeContentType;
        };
  };
};

export type PublishCampaignMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type PublishCampaignMutation = {
  __typename: 'Mutation';
  publishCampaign: {
    __typename: 'Campaign';
    id: string;
    status: CampaignStatus;
  };
};

export type DeleteCampaignMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type DeleteCampaignMutation = {
  __typename: 'Mutation';
  deleteCampaign: string;
};

export type MyCampaignReportsQueryVariables = Exact<{
  input: MyCampaignReportsInput;
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type MyCampaignReportsQuery = {
  __typename: 'Query';
  myCampaignReports: {
    __typename: 'CampaignReportListItemPaginator';
    data: Array<{
      __typename: 'CampaignReportListItem';
      id: string;
      campaign: { __typename: 'Campaign'; id: string; name: string };
      reportable:
        | {
            __typename: 'InstagramPostCampaignReport';
            id: string;
            completedCount: number;
            followerCount: number;
            cpv?: number | null;
            rewardValue: number;
          }
        | {
            __typename: 'InstagramReelCampaignReport';
            id: string;
            completedCount: number;
            followerCount: number;
            cpv?: number | null;
            rewardValue: number;
          }
        | {
            __typename: 'InstagramStoryCampaignReport';
            id: string;
            completedCount: number;
            followerCount: number;
            cpv?: number | null;
            rewardValue: number;
          }
        | {
            __typename: 'TiktokVideoCampaignReport';
            id: string;
            completedCount: number;
            followerCount: number;
            cpv?: number | null;
            rewardValue: number;
          }
        | {
            __typename: 'YoutubeVideoCampaignReport';
            id: string;
            completedCount: number;
            subscriberCount: number;
            cpv?: number | null;
            rewardValue: number;
          };
    }>;
    paginatorInfo: {
      __typename: 'PaginatorInfo';
      currentPage: number;
      hasMorePages: boolean;
    };
  };
};

export type MyCampaignsQueryVariables = Exact<{
  input: MyCampaignsInput;
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type MyCampaignsQuery = {
  __typename: 'Query';
  myCampaigns: {
    __typename: 'CampaignListItemPaginator';
    data: Array<{
      __typename: 'CampaignListItem';
      id: string;
      campaign: {
        __typename: 'Campaign';
        id: string;
        name: string;
        status: CampaignStatus;
        headerImage: {
          __typename: 'Download';
          uri: string;
          hash?: string | null;
        };
        brand: {
          __typename: 'Brand';
          id: string;
          name: string;
          logo: { __typename: 'Download'; uri: string; hash?: string | null };
        };
        typeable:
          | {
              __typename: 'InstagramCampaign';
              id: string;
              channelType: ChannelType;
              instagramContentType: InstagramContentType;
            }
          | {
              __typename: 'TiktokCampaign';
              id: string;
              channelType: ChannelType;
              tiktokContentType: TiktokContentType;
            }
          | {
              __typename: 'YoutubeCampaign';
              id: string;
              channelType: ChannelType;
              youtubeContentType: YoutubeContentType;
            };
      };
      statistics?: {
        __typename: 'CampaignStatistics';
        id: string;
        applicationsToReview: number;
        previewsToReview: number;
      } | null;
    }>;
    paginatorInfo: {
      __typename: 'PaginatorInfo';
      currentPage: number;
      hasMorePages: boolean;
    };
  };
};

export type CreateCampaignMutationVariables = Exact<{
  input: CreateCampaignInput;
}>;

export type CreateCampaignMutation = {
  __typename: 'Mutation';
  createCampaign: {
    __typename: 'Campaign';
    id: string;
    name: string;
    description: string;
    status: CampaignStatus;
    rewardType: CampaignRewardType;
    fixedMoneyRewardValue?: number | null;
    applyUntil?: string | null;
    address?: string | null;
    includeContentFileOnSubmission: boolean;
    taskItems: Array<{
      __typename: 'TaskItem';
      id: string;
      type: TaskItemType;
      taskable:
        | { __typename: 'FreeTextTask'; id: string; description: string }
        | { __typename: 'HashtagTask'; id: string; hashtags: Array<string> }
        | { __typename: 'WebsiteTask'; id: string; url: string };
    }>;
    headerImage: { __typename: 'Download'; uri: string; hash?: string | null };
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      logo: { __typename: 'Download'; uri: string; hash?: string | null };
    };
    category: { __typename: 'Category'; id: string; name: string };
    gifts: Array<{
      __typename: 'CampaignGift';
      id: string;
      type: CampaignGiftType;
      name: string;
      description: string;
    }>;
    questions: Array<{ __typename: 'Question'; id: string; question: string }>;
    typeable:
      | {
          __typename: 'InstagramCampaign';
          id: string;
          channelType: ChannelType;
          instagramContentType: InstagramContentType;
        }
      | {
          __typename: 'TiktokCampaign';
          id: string;
          channelType: ChannelType;
          tiktokContentType: TiktokContentType;
        }
      | {
          __typename: 'YoutubeCampaign';
          id: string;
          channelType: ChannelType;
          youtubeContentType: YoutubeContentType;
        };
  };
};

export type UpdateCampaignMutationVariables = Exact<{
  input: UpdateCampaignInput;
}>;

export type UpdateCampaignMutation = {
  __typename: 'Mutation';
  updateCampaign: {
    __typename: 'Campaign';
    id: string;
    name: string;
    description: string;
    status: CampaignStatus;
    rewardType: CampaignRewardType;
    fixedMoneyRewardValue?: number | null;
    applyUntil?: string | null;
    address?: string | null;
    includeContentFileOnSubmission: boolean;
    taskItems: Array<{
      __typename: 'TaskItem';
      id: string;
      type: TaskItemType;
      taskable:
        | { __typename: 'FreeTextTask'; id: string; description: string }
        | { __typename: 'HashtagTask'; id: string; hashtags: Array<string> }
        | { __typename: 'WebsiteTask'; id: string; url: string };
    }>;
    headerImage: { __typename: 'Download'; uri: string; hash?: string | null };
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      logo: { __typename: 'Download'; uri: string; hash?: string | null };
    };
    category: { __typename: 'Category'; id: string; name: string };
    gifts: Array<{
      __typename: 'CampaignGift';
      id: string;
      type: CampaignGiftType;
      name: string;
      description: string;
    }>;
    questions: Array<{ __typename: 'Question'; id: string; question: string }>;
    typeable:
      | {
          __typename: 'InstagramCampaign';
          id: string;
          channelType: ChannelType;
          instagramContentType: InstagramContentType;
        }
      | {
          __typename: 'TiktokCampaign';
          id: string;
          channelType: ChannelType;
          tiktokContentType: TiktokContentType;
        }
      | {
          __typename: 'YoutubeCampaign';
          id: string;
          channelType: ChannelType;
          youtubeContentType: YoutubeContentType;
        };
  };
};

export type UpdateCampaignHeaderImageMutationVariables = Exact<{
  input: UpdateCampaignHeaderImageInput;
}>;

export type UpdateCampaignHeaderImageMutation = {
  __typename: 'Mutation';
  updateCampaignHeaderImage: {
    __typename: 'Campaign';
    id: string;
    headerImage: { __typename: 'Download'; uri: string; hash?: string | null };
  };
};

export type CompleteCampaignMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type CompleteCampaignMutation = {
  __typename: 'Mutation';
  completeCampaign: {
    __typename: 'Campaign';
    id: string;
    status: CampaignStatus;
  };
};

export type CampaignPreviewQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type CampaignPreviewQuery = {
  __typename: 'Query';
  campaign: {
    __typename: 'Campaign';
    id: string;
    name: string;
    description: string;
    status: CampaignStatus;
    applyUntil?: string | null;
    updatedAt: string;
    publishedAt?: string | null;
    address?: string | null;
    includeContentFileOnSubmission: boolean;
    rewardType: CampaignRewardType;
    fixedMoneyRewardValue?: number | null;
    headerImage: { __typename: 'Download'; uri: string; hash?: string | null };
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      logo: { __typename: 'Download'; uri: string; hash?: string | null };
    };
    category: { __typename: 'Category'; id: string; name: string };
    gifts: Array<{
      __typename: 'CampaignGift';
      id: string;
      name: string;
      type: CampaignGiftType;
    }>;
    taskItems: Array<{
      __typename: 'TaskItem';
      id: string;
      type: TaskItemType;
      taskable:
        | { __typename: 'FreeTextTask'; id: string; description: string }
        | { __typename: 'HashtagTask'; id: string; hashtags: Array<string> }
        | { __typename: 'WebsiteTask'; id: string; url: string };
    }>;
    typeable:
      | {
          __typename: 'InstagramCampaign';
          id: string;
          channelType: ChannelType;
          instagramContentType: InstagramContentType;
        }
      | {
          __typename: 'TiktokCampaign';
          id: string;
          channelType: ChannelType;
          tiktokContentType: TiktokContentType;
        }
      | {
          __typename: 'YoutubeCampaign';
          id: string;
          channelType: ChannelType;
          youtubeContentType: YoutubeContentType;
        };
  };
};

export type InstagramPostQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type InstagramPostQuery = {
  __typename: 'Query';
  campaign: {
    __typename: 'Campaign';
    id: string;
    name: string;
    description: string;
    applyUntil?: string | null;
    rewardType: CampaignRewardType;
    fixedMoneyRewardValue?: number | null;
    headerImage: { __typename: 'Download'; uri: string; hash?: string | null };
    brand: { __typename: 'Brand'; id: string; name: string };
    gifts: Array<{ __typename: 'CampaignGift'; id: string; name: string }>;
    typeable:
      | {
          __typename: 'InstagramCampaign';
          id: string;
          channelType: ChannelType;
          instagramContentType: InstagramContentType;
        }
      | {
          __typename: 'TiktokCampaign';
          id: string;
          channelType: ChannelType;
          tiktokContentType: TiktokContentType;
        }
      | {
          __typename: 'YoutubeCampaign';
          id: string;
          channelType: ChannelType;
          youtubeContentType: YoutubeContentType;
        };
  };
};

export type DiscoverCampaignsForSitemapQueryVariables = Exact<{
  input: DiscoverCampaignsInput;
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type DiscoverCampaignsForSitemapQuery = {
  __typename: 'Query';
  discoverCampaigns: {
    __typename: 'CampaignPaginator';
    data: Array<{ __typename: 'Campaign'; id: string; updatedAt: string }>;
  };
};

export type DiscoverCampaignsForInstagramPostQueryVariables = Exact<{
  input: DiscoverCampaignsInput;
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type DiscoverCampaignsForInstagramPostQuery = {
  __typename: 'Query';
  discoverCampaigns: {
    __typename: 'CampaignPaginator';
    data: Array<{ __typename: 'Campaign'; id: string; description: string }>;
  };
};

export type PublicDiscoverCampaignsQueryVariables = Exact<{
  input: DiscoverCampaignsInput;
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type PublicDiscoverCampaignsQuery = {
  __typename: 'Query';
  discoverCampaigns: {
    __typename: 'CampaignPaginator';
    data: Array<{
      __typename: 'Campaign';
      id: string;
      name: string;
      headerImage: {
        __typename: 'Download';
        uri: string;
        hash?: string | null;
      };
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
        logo: { __typename: 'Download'; uri: string; hash?: string | null };
      };
      category: { __typename: 'Category'; id: string; name: string };
      typeable:
        | {
            __typename: 'InstagramCampaign';
            id: string;
            channelType: ChannelType;
            instagramContentType: InstagramContentType;
          }
        | {
            __typename: 'TiktokCampaign';
            id: string;
            channelType: ChannelType;
            tiktokContentType: TiktokContentType;
          }
        | {
            __typename: 'YoutubeCampaign';
            id: string;
            channelType: ChannelType;
            youtubeContentType: YoutubeContentType;
          };
    }>;
  };
};

export type CampaignReportForCampaignQueryVariables = Exact<{
  campaignId: Scalars['UUID']['input'];
}>;

export type CampaignReportForCampaignQuery = {
  __typename: 'Query';
  campaignReportForCampaign?:
    | {
        __typename: 'InstagramPostCampaignReport';
        id: string;
        completedCount: number;
        followerCount: number;
        views: number;
        saves: number;
        engagementRate?: number | null;
        cpv?: number | null;
        rewardValue: number;
        creatorHasCampaignReports: Array<{
          __typename: 'InstagramPostCreatorHasCampaignReport';
          id: string;
          followerCount: number;
          views: number;
          saves: number;
          engagementRate?: number | null;
          cpv?: number | null;
          rewardValue: number;
          lastFetchedAt?: string | null;
          creatorHasCampaign: {
            __typename: 'CreatorHasCampaign';
            id: string;
            status: CreatorHasCampaignStatus;
            creator: { __typename: 'Creator'; id: string; username: string };
          };
        }>;
      }
    | {
        __typename: 'InstagramReelCampaignReport';
        id: string;
        completedCount: number;
        followerCount: number;
        views: number;
        likes: number;
        comments: number;
        shares: number;
        reach: number;
        engagementRate?: number | null;
        cpv?: number | null;
        rewardValue: number;
        creatorHasCampaignReports: Array<{
          __typename: 'InstagramReelCreatorHasCampaignReport';
          id: string;
          followerCount: number;
          views: number;
          likes: number;
          comments: number;
          shares: number;
          reach: number;
          engagementRate?: number | null;
          cpv?: number | null;
          rewardValue: number;
          lastFetchedAt?: string | null;
          creatorHasCampaign: {
            __typename: 'CreatorHasCampaign';
            id: string;
            status: CreatorHasCampaignStatus;
            creator: { __typename: 'Creator'; id: string; username: string };
          };
        }>;
      }
    | {
        __typename: 'InstagramStoryCampaignReport';
        id: string;
        completedCount: number;
        followerCount: number;
        views: number;
        engagementRate?: number | null;
        cpv?: number | null;
        rewardValue: number;
        creatorHasCampaignReports: Array<{
          __typename: 'InstagramStoryCreatorHasCampaignReport';
          id: string;
          followerCount: number;
          views: number;
          engagementRate?: number | null;
          cpv?: number | null;
          rewardValue: number;
          lastFetchedAt?: string | null;
          creatorHasCampaign: {
            __typename: 'CreatorHasCampaign';
            id: string;
            status: CreatorHasCampaignStatus;
            creator: { __typename: 'Creator'; id: string; username: string };
          };
        }>;
      }
    | {
        __typename: 'TiktokVideoCampaignReport';
        id: string;
        completedCount: number;
        followerCount: number;
        views: number;
        likes: number;
        comments: number;
        shares: number;
        engagementRate?: number | null;
        cpv?: number | null;
        rewardValue: number;
        creatorHasCampaignReports: Array<{
          __typename: 'TiktokVideoCreatorHasCampaignReport';
          id: string;
          followerCount: number;
          views: number;
          likes: number;
          comments: number;
          shares: number;
          engagementRate?: number | null;
          cpv?: number | null;
          rewardValue: number;
          lastFetchedAt?: string | null;
          creatorHasCampaign: {
            __typename: 'CreatorHasCampaign';
            id: string;
            status: CreatorHasCampaignStatus;
            creator: { __typename: 'Creator'; id: string; username: string };
          };
        }>;
      }
    | {
        __typename: 'YoutubeVideoCampaignReport';
        id: string;
        completedCount: number;
        subscriberCount: number;
        views: number;
        likes: number;
        comments: number;
        favorites: number;
        engagementRate?: number | null;
        cpv?: number | null;
        rewardValue: number;
        creatorHasCampaignReports: Array<{
          __typename: 'YoutubeVideoCreatorHasCampaignReport';
          id: string;
          subscriberCount: number;
          views: number;
          likes: number;
          comments: number;
          favorites: number;
          engagementRate?: number | null;
          cpv?: number | null;
          rewardValue: number;
          lastFetchedAt?: string | null;
          creatorHasCampaign: {
            __typename: 'CreatorHasCampaign';
            id: string;
            status: CreatorHasCampaignStatus;
            creator: { __typename: 'Creator'; id: string; username: string };
          };
        }>;
      }
    | null;
};

export type CampaignStatisticsForCampaignQueryVariables = Exact<{
  campaignId: Scalars['UUID']['input'];
}>;

export type CampaignStatisticsForCampaignQuery = {
  __typename: 'Query';
  campaignStatisticsForCampaign?: {
    __typename: 'CampaignStatistics';
    id: string;
    applications: number;
    applicationsAccepted: number;
    previewsAccepted: number;
    contentSubmissions: number;
    applicationsToReview: number;
    previewsToReview: number;
  } | null;
};

export type CategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type CategoriesQuery = {
  __typename: 'Query';
  categories: Array<{ __typename: 'Category'; id: string; name: string }>;
};

export type ChannelsQueryVariables = Exact<{ [key: string]: never }>;

export type ChannelsQuery = {
  __typename: 'Query';
  whoAmI:
    | {
        __typename: 'CreatorAuthenticatable';
        id: string;
        user: {
          __typename: 'Creator';
          id: string;
          channels: Array<{
            __typename: 'Channel';
            id: string;
            isAccessTokenValid: boolean;
            channelableType: ChannelType;
            channelable:
              | {
                  __typename: 'InstagramChannel';
                  id: string;
                  username: string;
                  url: string;
                  followersCount: number;
                  averageEngagementRate?: number | null;
                  averageReach?: number | null;
                  mediaCount: number;
                }
              | {
                  __typename: 'TiktokChannel';
                  id: string;
                  displayName: string;
                  url: string;
                  followerCount: number;
                  averageEngagementRate?: number | null;
                  averageReach?: number | null;
                  videoCount: number;
                }
              | {
                  __typename: 'YoutubeChannel';
                  id: string;
                  title: string;
                  url: string;
                  subscriberCount: number;
                  averageEngagementRate?: number | null;
                  averageReach?: number | null;
                  videoCount: number;
                };
          }>;
        };
      }
    | { __typename: 'ManagerAuthenticatable' };
};

export type DeleteChannelMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type DeleteChannelMutation = {
  __typename: 'Mutation';
  deleteChannel: {
    __typename: 'Creator';
    id: string;
    channels: Array<{
      __typename: 'Channel';
      id: string;
      isAccessTokenValid: boolean;
      channelableType: ChannelType;
      channelable:
        | {
            __typename: 'InstagramChannel';
            id: string;
            username: string;
            url: string;
            followersCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            mediaCount: number;
          }
        | {
            __typename: 'TiktokChannel';
            id: string;
            displayName: string;
            url: string;
            followerCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          }
        | {
            __typename: 'YoutubeChannel';
            id: string;
            title: string;
            url: string;
            subscriberCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          };
    }>;
  };
};

export type StripeCustomerPortalUrlQueryVariables = Exact<{
  input: StripeCustomerPortalUrlInput;
}>;

export type StripeCustomerPortalUrlQuery = {
  __typename: 'Query';
  stripeCustomerPortalUrl: string;
};

export type RegisterCompanyMutationVariables = Exact<{
  input: RegisterCompanyInput;
}>;

export type RegisterCompanyMutation = {
  __typename: 'Mutation';
  registerCompany: { __typename: 'Company'; id: string; name: string };
};

export type CheckoutSubscriptionForCompanyMutationVariables = Exact<{
  input: CheckoutSubscriptionForCompanyInput;
}>;

export type CheckoutSubscriptionForCompanyMutation = {
  __typename: 'Mutation';
  checkoutSubscriptionForCompany: string;
};

export type BrandUsageQueryVariables = Exact<{ [key: string]: never }>;

export type BrandUsageQuery = {
  __typename: 'Query';
  myCompany: {
    __typename: 'Company';
    id: string;
    currentBrandCount: number;
    currentSubscription?: {
      __typename: 'SubscriptionObject';
      id: string;
      subscriptionPlan?: {
        __typename: 'SubscriptionPlan';
        id: string;
        maxBrandCount?: number | null;
      } | null;
    } | null;
  };
};

export type CampaignUsageQueryVariables = Exact<{ [key: string]: never }>;

export type CampaignUsageQuery = {
  __typename: 'Query';
  myCompany: {
    __typename: 'Company';
    id: string;
    currentActiveCampaignCount: number;
    currentSubscription?: {
      __typename: 'SubscriptionObject';
      id: string;
      subscriptionPlan?: {
        __typename: 'SubscriptionPlan';
        id: string;
        maxCampaignCount?: number | null;
      } | null;
    } | null;
  };
};

export type StorageSpaceUsageQueryVariables = Exact<{ [key: string]: never }>;

export type StorageSpaceUsageQuery = {
  __typename: 'Query';
  myCompany: {
    __typename: 'Company';
    id: string;
    usedStorageSpace: number;
    currentSubscription?: {
      __typename: 'SubscriptionObject';
      id: string;
      subscriptionPlan?: {
        __typename: 'SubscriptionPlan';
        id: string;
        storageSpaceLimit: number;
      } | null;
    } | null;
  };
};

export type MyCompanyQueryVariables = Exact<{ [key: string]: never }>;

export type MyCompanyQuery = {
  __typename: 'Query';
  myCompany: {
    __typename: 'Company';
    id: string;
    name: string;
    currentActiveCampaignCount: number;
    currentBrandCount: number;
    usedStorageSpace: number;
    currentSubscription?: {
      __typename: 'SubscriptionObject';
      id: string;
      status: string;
      subscriptionPlan?: {
        __typename: 'SubscriptionPlan';
        id: string;
        name: string;
        bookingFeePercentage: number;
        minBookingFee: number;
        maxCampaignCount?: number | null;
        maxBrandCount?: number | null;
        storageSpaceLimit: number;
      } | null;
    } | null;
  };
};

export type CreatorAuthenticatableContextQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CreatorAuthenticatableContextQuery = {
  __typename: 'Query';
  whoAmI:
    | {
        __typename: 'CreatorAuthenticatable';
        id: string;
        locale: string;
        hasSSOLogin: boolean;
        user: {
          __typename: 'Creator';
          id: string;
          username: string;
          description?: string | null;
          isUnreliable: boolean;
          channels: Array<{
            __typename: 'Channel';
            id: string;
            isAccessTokenValid: boolean;
            channelableType: ChannelType;
            channelable:
              | {
                  __typename: 'InstagramChannel';
                  id: string;
                  username: string;
                  url: string;
                  followersCount: number;
                  averageEngagementRate?: number | null;
                  averageReach?: number | null;
                  mediaCount: number;
                }
              | {
                  __typename: 'TiktokChannel';
                  id: string;
                  displayName: string;
                  url: string;
                  followerCount: number;
                  averageEngagementRate?: number | null;
                  averageReach?: number | null;
                  videoCount: number;
                }
              | {
                  __typename: 'YoutubeChannel';
                  id: string;
                  title: string;
                  url: string;
                  subscriberCount: number;
                  averageEngagementRate?: number | null;
                  averageReach?: number | null;
                  videoCount: number;
                };
          }>;
          profilePicture?: {
            __typename: 'Download';
            uri: string;
            hash?: string | null;
          } | null;
        };
      }
    | { __typename: 'ManagerAuthenticatable' };
};

export type CreatorProfileQueryVariables = Exact<{ [key: string]: never }>;

export type CreatorProfileQuery = {
  __typename: 'Query';
  whoAmI:
    | {
        __typename: 'CreatorAuthenticatable';
        id: string;
        locale: string;
        hasSSOLogin: boolean;
        user: {
          __typename: 'Creator';
          id: string;
          username: string;
          description?: string | null;
          isUnreliable: boolean;
          channels: Array<{
            __typename: 'Channel';
            id: string;
            isAccessTokenValid: boolean;
            channelableType: ChannelType;
            channelable:
              | {
                  __typename: 'InstagramChannel';
                  id: string;
                  username: string;
                  url: string;
                  followersCount: number;
                  averageEngagementRate?: number | null;
                  averageReach?: number | null;
                  mediaCount: number;
                }
              | {
                  __typename: 'TiktokChannel';
                  id: string;
                  displayName: string;
                  url: string;
                  followerCount: number;
                  averageEngagementRate?: number | null;
                  averageReach?: number | null;
                  videoCount: number;
                }
              | {
                  __typename: 'YoutubeChannel';
                  id: string;
                  title: string;
                  url: string;
                  subscriberCount: number;
                  averageEngagementRate?: number | null;
                  averageReach?: number | null;
                  videoCount: number;
                };
          }>;
          profilePicture?: {
            __typename: 'Download';
            uri: string;
            hash?: string | null;
          } | null;
        };
      }
    | { __typename: 'ManagerAuthenticatable' };
};

export type CreatorsQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CreatorsQuery = {
  __typename: 'Query';
  creators: {
    __typename: 'CreatorPaginator';
    data: Array<{
      __typename: 'Creator';
      id: string;
      username: string;
      isUnreliable: boolean;
      channels: Array<{
        __typename: 'Channel';
        id: string;
        channelableType: ChannelType;
      }>;
    }>;
    paginatorInfo: { __typename: 'PaginatorInfo'; total: number };
  };
};

export type RegisterCreatorMutationVariables = Exact<{
  input: RegisterCreatorInput;
}>;

export type RegisterCreatorMutation = {
  __typename: 'Mutation';
  registerCreator: {
    __typename: 'CreatorAuthenticatable';
    id: string;
    locale: string;
    hasSSOLogin: boolean;
    user: {
      __typename: 'Creator';
      id: string;
      username: string;
      description?: string | null;
      isUnreliable: boolean;
      profilePicture?: {
        __typename: 'Download';
        uri: string;
        hash?: string | null;
      } | null;
    };
  };
};

export type SocialRegisterCreatorMutationVariables = Exact<{
  input: SocialRegisterCreatorInput;
}>;

export type SocialRegisterCreatorMutation = {
  __typename: 'Mutation';
  socialRegisterCreator: string;
};

export type DeactivateCreatorMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DeactivateCreatorMutation = {
  __typename: 'Mutation';
  deactivateCreator: string;
};

export type ForgotCreatorPasswordMutationVariables = Exact<{
  input: ForgotCreatorPasswordInput;
}>;

export type ForgotCreatorPasswordMutation = {
  __typename: 'Mutation';
  forgotCreatorPassword: string;
};

export type RecoverCreatorPasswordMutationVariables = Exact<{
  input: RecoverCreatorPasswordInput;
}>;

export type RecoverCreatorPasswordMutation = {
  __typename: 'Mutation';
  recoverCreatorPassword: string;
};

export type VerifyCreatorEmailMutationVariables = Exact<{
  input: VerifyCreatorEmailInput;
}>;

export type VerifyCreatorEmailMutation = {
  __typename: 'Mutation';
  verifyCreatorEmail: string;
};

export type ResendCreatorVerificationEmailMutationVariables = Exact<{
  input: ResendCreatorVerificationEmailInput;
}>;

export type ResendCreatorVerificationEmailMutation = {
  __typename: 'Mutation';
  resendCreatorVerificationEmail: string;
};

export type RequestVerificationOfNewCreatorEmailMutationVariables = Exact<{
  input: RequestVerificationOfNewCreatorEmailInput;
}>;

export type RequestVerificationOfNewCreatorEmailMutation = {
  __typename: 'Mutation';
  requestVerificationOfNewCreatorEmail: string;
};

export type VerifyAndUpdateCreatorEmailMutationVariables = Exact<{
  input: VerifyCreatorEmailInput;
}>;

export type VerifyAndUpdateCreatorEmailMutation = {
  __typename: 'Mutation';
  verifyAndUpdateCreatorEmail: { __typename: 'Creator'; id: string };
};

export type UpdateCreatorProfileMutationVariables = Exact<{
  input: UpdateCreatorProfileInput;
}>;

export type UpdateCreatorProfileMutation = {
  __typename: 'Mutation';
  updateCreatorProfile: {
    __typename: 'Creator';
    id: string;
    username: string;
    description?: string | null;
  };
};

export type UpdateCreatorLocaleMutationVariables = Exact<{
  input: UpdateCreatorLocaleInput;
}>;

export type UpdateCreatorLocaleMutation = {
  __typename: 'Mutation';
  updateCreatorLocale: {
    __typename: 'CreatorAuthenticatable';
    id: string;
    locale: string;
  };
};

export type UpdateCreatorPasswordMutationVariables = Exact<{
  input: UpdateCreatorPasswordInput;
}>;

export type UpdateCreatorPasswordMutation = {
  __typename: 'Mutation';
  updateCreatorPassword: string;
};

export type UploadCreatorProfilePictureMutationVariables = Exact<{
  input: UploadCreatorProfilePictureInput;
}>;

export type UploadCreatorProfilePictureMutation = {
  __typename: 'Mutation';
  uploadCreatorProfilePicture: {
    __typename: 'Creator';
    id: string;
    profilePicture?: {
      __typename: 'Download';
      uri: string;
      hash?: string | null;
    } | null;
  };
};

export type LoginCreatorMutationVariables = Exact<{
  input: LoginCreatorInput;
}>;

export type LoginCreatorMutation = {
  __typename: 'Mutation';
  loginCreator: string;
};

export type LogoutCreatorMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutCreatorMutation = {
  __typename: 'Mutation';
  logoutCreator: string;
};

export type SocialLoginCreatorMutationVariables = Exact<{
  input: SocialLoginCreatorInput;
}>;

export type SocialLoginCreatorMutation = {
  __typename: 'Mutation';
  socialLoginCreator: string;
};

export type SetCreatorExpoPushTokenMutationVariables = Exact<{
  input: SetCreatorExpoPushTokenInput;
}>;

export type SetCreatorExpoPushTokenMutation = {
  __typename: 'Mutation';
  setCreatorExpoPushToken: string;
};

export type ImpersonateCreatorMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type ImpersonateCreatorMutation = {
  __typename: 'Mutation';
  impersonateCreator: string;
};

export type MyCreatorDeliveryAddressTemplatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type MyCreatorDeliveryAddressTemplatesQuery = {
  __typename: 'Query';
  myCreatorDeliveryAddressTemplates: Array<{
    __typename: 'CreatorDeliveryAddressTemplate';
    id: string;
    recipientName: string;
    streetAddressLine1: string;
    streetAddressLine2?: string | null;
    postcode: string;
    city: string;
    country: string;
  }>;
};

export type CreateCreatorDeliveryAddressTemplateMutationVariables = Exact<{
  input: CreateCreatorDeliveryAddressTemplateInput;
}>;

export type CreateCreatorDeliveryAddressTemplateMutation = {
  __typename: 'Mutation';
  createCreatorDeliveryAddressTemplate: {
    __typename: 'CreatorDeliveryAddressTemplate';
    id: string;
    recipientName: string;
    streetAddressLine1: string;
    streetAddressLine2?: string | null;
    postcode: string;
    city: string;
    country: string;
  };
};

export type UpdateCreatorDeliveryAddressTemplateMutationVariables = Exact<{
  input: UpdateCreatorDeliveryAddressTemplateInput;
}>;

export type UpdateCreatorDeliveryAddressTemplateMutation = {
  __typename: 'Mutation';
  updateCreatorDeliveryAddressTemplate: {
    __typename: 'CreatorDeliveryAddressTemplate';
    id: string;
    recipientName: string;
    streetAddressLine1: string;
    streetAddressLine2?: string | null;
    postcode: string;
    city: string;
    country: string;
  };
};

export type DeleteCreatorDeliveryAddressTemplateMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type DeleteCreatorDeliveryAddressTemplateMutation = {
  __typename: 'Mutation';
  deleteCreatorDeliveryAddressTemplate: string;
};

export type ApplyForCampaignMutationVariables = Exact<{
  input: ApplyForCampaignInput;
}>;

export type ApplyForCampaignMutation = {
  __typename: 'Mutation';
  applyForCampaign: {
    __typename: 'CreatorHasCampaign';
    id: string;
    status: CreatorHasCampaignStatus;
    rewardValue: number;
    campaign: { __typename: 'Campaign'; id: string };
  };
};

export type UnapplyFromCampaignMutationVariables = Exact<{
  creatorHasCampaignId: Scalars['UUID']['input'];
}>;

export type UnapplyFromCampaignMutation = {
  __typename: 'Mutation';
  unapplyFromCampaign: string;
};

export type MyCreatorHasCampaignsQueryVariables = Exact<{
  input: MyCreatorHasCampaignsInput;
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type MyCreatorHasCampaignsQuery = {
  __typename: 'Query';
  myCreatorHasCampaigns: {
    __typename: 'CreatorHasCampaignPaginator';
    data: Array<{
      __typename: 'CreatorHasCampaign';
      id: string;
      status: CreatorHasCampaignStatus;
      campaign: {
        __typename: 'Campaign';
        id: string;
        name: string;
        headerImage: {
          __typename: 'Download';
          uri: string;
          hash?: string | null;
        };
        brand: {
          __typename: 'Brand';
          id: string;
          name: string;
          logo: { __typename: 'Download'; uri: string; hash?: string | null };
        };
      };
    }>;
    paginatorInfo: {
      __typename: 'PaginatorInfo';
      hasMorePages: boolean;
      currentPage: number;
    };
  };
};

export type MyCreatorHasCampaignForCampaignQueryVariables = Exact<{
  campaignId: Scalars['UUID']['input'];
}>;

export type MyCreatorHasCampaignForCampaignQuery = {
  __typename: 'Query';
  myCreatorHasCampaignForCampaign?: {
    __typename: 'CreatorHasCampaign';
    id: string;
    status: CreatorHasCampaignStatus;
    contentUrl?: string | null;
    discontinuedReason?: string | null;
    rewardValue: number;
    answers: Array<{
      __typename: 'Answer';
      id: string;
      answer: string;
      question: { __typename: 'Question'; id: string; question: string };
    }>;
    previewRevisions: Array<{
      __typename: 'PreviewRevision';
      id: string;
      caption: string;
      retriedReason?: string | null;
    }>;
    channel?: {
      __typename: 'Channel';
      id: string;
      isAccessTokenValid: boolean;
      channelableType: ChannelType;
      channelable:
        | {
            __typename: 'InstagramChannel';
            id: string;
            username: string;
            url: string;
            followersCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            mediaCount: number;
          }
        | {
            __typename: 'TiktokChannel';
            id: string;
            displayName: string;
            url: string;
            followerCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          }
        | {
            __typename: 'YoutubeChannel';
            id: string;
            title: string;
            url: string;
            subscriberCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          };
    } | null;
  } | null;
};

export type CampaignGiftCodeQueryVariables = Exact<{
  campaignId: Scalars['UUID']['input'];
}>;

export type CampaignGiftCodeQuery = {
  __typename: 'Query';
  myCreatorHasCampaignForCampaign?: {
    __typename: 'CreatorHasCampaign';
    id: string;
    campaignGiftCodes: Array<{
      __typename: 'CampaignGiftCode';
      id: string;
      code: string;
      gift: { __typename: 'CampaignGift'; id: string };
    }>;
  } | null;
};

export type SubmitPreviewMutationVariables = Exact<{
  input: SubmitPreviewInput;
}>;

export type SubmitPreviewMutation = {
  __typename: 'Mutation';
  submitPreview: {
    __typename: 'CreatorHasCampaign';
    id: string;
    status: CreatorHasCampaignStatus;
    previewRevisions: Array<{
      __typename: 'PreviewRevision';
      id: string;
      caption: string;
    }>;
  };
};

export type SubmitContentMutationVariables = Exact<{
  input: SubmitContentInput;
}>;

export type SubmitContentMutation = {
  __typename: 'Mutation';
  submitContent: {
    __typename: 'CreatorHasCampaign';
    id: string;
    status: CreatorHasCampaignStatus;
    contentUrl?: string | null;
  };
};

export type CreatorsOfCampaignQueryVariables = Exact<{
  input: CreatorsOfCampaignInput;
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CreatorsOfCampaignQuery = {
  __typename: 'Query';
  creatorsOfCampaign: {
    __typename: 'CreatorHasCampaignPaginator';
    data: Array<{
      __typename: 'CreatorHasCampaign';
      id: string;
      status: CreatorHasCampaignStatus;
      rewardValue: number;
      channel?: {
        __typename: 'Channel';
        id: string;
        isAccessTokenValid: boolean;
        channelableType: ChannelType;
        channelable:
          | {
              __typename: 'InstagramChannel';
              id: string;
              username: string;
              url: string;
              followersCount: number;
              averageEngagementRate?: number | null;
              averageReach?: number | null;
              mediaCount: number;
            }
          | {
              __typename: 'TiktokChannel';
              id: string;
              displayName: string;
              url: string;
              followerCount: number;
              averageEngagementRate?: number | null;
              averageReach?: number | null;
              videoCount: number;
            }
          | {
              __typename: 'YoutubeChannel';
              id: string;
              title: string;
              url: string;
              subscriberCount: number;
              averageEngagementRate?: number | null;
              averageReach?: number | null;
              videoCount: number;
            };
      } | null;
      creator: {
        __typename: 'Creator';
        id: string;
        username: string;
        isUnreliable: boolean;
        profilePicture?: {
          __typename: 'Download';
          uri: string;
          hash?: string | null;
        } | null;
      };
    }>;
    paginatorInfo: {
      __typename: 'PaginatorInfo';
      currentPage: number;
      hasMorePages: boolean;
    };
  };
};

export type CreatorHasCampaignQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type CreatorHasCampaignQuery = {
  __typename: 'Query';
  creatorHasCampaign: {
    __typename: 'CreatorHasCampaign';
    id: string;
    rewardValue: number;
    status: CreatorHasCampaignStatus;
    discontinuedReason?: string | null;
    contentUrl?: string | null;
    channel?: {
      __typename: 'Channel';
      id: string;
      isAccessTokenValid: boolean;
      channelableType: ChannelType;
      channelable:
        | {
            __typename: 'InstagramChannel';
            id: string;
            username: string;
            url: string;
            followersCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            mediaCount: number;
            ageFollowerDemographics: Array<{
              __typename: 'AgeFollowerDemoGraphics';
              id: string;
              age: string;
              percentage: number;
            }>;
            countryFollowerDemographics: Array<{
              __typename: 'CountryFollowerDemoGraphics';
              id: string;
              country: string;
              percentage: number;
            }>;
            genderFollowerDemographics: Array<{
              __typename: 'GenderFollowerDemoGraphics';
              id: string;
              gender: string;
              percentage: number;
            }>;
          }
        | {
            __typename: 'TiktokChannel';
            id: string;
            displayName: string;
            url: string;
            followerCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          }
        | {
            __typename: 'YoutubeChannel';
            id: string;
            title: string;
            url: string;
            subscriberCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          };
    } | null;
    campaignGiftCodes: Array<{
      __typename: 'CampaignGiftCode';
      id: string;
      code: string;
      gift: {
        __typename: 'CampaignGift';
        id: string;
        type: CampaignGiftType;
        name: string;
      };
    }>;
    answers: Array<{
      __typename: 'Answer';
      id: string;
      answer: string;
      question: { __typename: 'Question'; id: string; question: string };
    }>;
    deliveryAddress?: {
      __typename: 'DeliveryAddress';
      id: string;
      recipientName: string;
      streetAddressLine1: string;
      streetAddressLine2?: string | null;
      postcode: string;
      city: string;
      country: string;
    } | null;
    previewRevisions: Array<{
      __typename: 'PreviewRevision';
      id: string;
      caption: string;
      retriedReason?: string | null;
    }>;
    creator: {
      __typename: 'Creator';
      id: string;
      username: string;
      description?: string | null;
      isUnreliable: boolean;
      profilePicture?: {
        __typename: 'Download';
        uri: string;
        hash?: string | null;
      } | null;
    };
    campaign: {
      __typename: 'Campaign';
      id: string;
      name: string;
      includeContentFileOnSubmission: boolean;
      headerImage: {
        __typename: 'Download';
        uri: string;
        hash?: string | null;
      };
      gifts: Array<{
        __typename: 'CampaignGift';
        id: string;
        type: CampaignGiftType;
        name: string;
      }>;
      questions: Array<{
        __typename: 'Question';
        id: string;
        question: string;
      }>;
      typeable:
        | {
            __typename: 'InstagramCampaign';
            id: string;
            channelType: ChannelType;
            instagramContentType: InstagramContentType;
          }
        | {
            __typename: 'TiktokCampaign';
            id: string;
            channelType: ChannelType;
            tiktokContentType: TiktokContentType;
          }
        | {
            __typename: 'YoutubeCampaign';
            id: string;
            channelType: ChannelType;
            youtubeContentType: YoutubeContentType;
          };
    };
  };
};

export type AcceptCampaignApplicationMutationVariables = Exact<{
  input: AcceptCampaignApplicationInput;
}>;

export type AcceptCampaignApplicationMutation = {
  __typename: 'Mutation';
  acceptCampaignApplication: {
    __typename: 'CreatorHasCampaign';
    id: string;
    status: CreatorHasCampaignStatus;
    campaignGiftCodes: Array<{
      __typename: 'CampaignGiftCode';
      id: string;
      code: string;
      gift: {
        __typename: 'CampaignGift';
        id: string;
        type: CampaignGiftType;
        name: string;
        description: string;
      };
    }>;
  };
};

export type DeclineCampaignApplicationMutationVariables = Exact<{
  creatorHasCampaignId: Scalars['UUID']['input'];
}>;

export type DeclineCampaignApplicationMutation = {
  __typename: 'Mutation';
  declineCampaignApplication: {
    __typename: 'CreatorHasCampaign';
    id: string;
    status: CreatorHasCampaignStatus;
  };
};

export type AcceptPreviewMutationVariables = Exact<{
  creatorHasCampaignId: Scalars['UUID']['input'];
}>;

export type AcceptPreviewMutation = {
  __typename: 'Mutation';
  acceptPreview: {
    __typename: 'CreatorHasCampaign';
    id: string;
    status: CreatorHasCampaignStatus;
  };
};

export type RetryPreviewMutationVariables = Exact<{
  input: RetryPreviewInput;
}>;

export type RetryPreviewMutation = {
  __typename: 'Mutation';
  retryPreview: {
    __typename: 'CreatorHasCampaign';
    id: string;
    status: CreatorHasCampaignStatus;
    previewRevisions: Array<{
      __typename: 'PreviewRevision';
      id: string;
      retriedReason?: string | null;
    }>;
  };
};

export type PreviewFileQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type PreviewFileQuery = {
  __typename: 'Query';
  previewFile: { __typename: 'Download'; uri: string; hash?: string | null };
};

export type CreatorHasCampaignContentFileQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type CreatorHasCampaignContentFileQuery = {
  __typename: 'Query';
  creatorHasCampaignContentFile: {
    __typename: 'Download';
    uri: string;
    hash?: string | null;
  };
};

export type UpdateApplyUntilMutationVariables = Exact<{
  input: UpdateApplyUntilInput;
}>;

export type UpdateApplyUntilMutation = {
  __typename: 'Mutation';
  updateApplyUntil: {
    __typename: 'Campaign';
    id: string;
    applyUntil?: string | null;
  };
};

export type CreatorHasCampaignMessageHeaderQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type CreatorHasCampaignMessageHeaderQuery = {
  __typename: 'Query';
  creatorHasCampaign: {
    __typename: 'CreatorHasCampaign';
    id: string;
    creator: {
      __typename: 'Creator';
      id: string;
      username: string;
      profilePicture?: {
        __typename: 'Download';
        hash?: string | null;
        uri: string;
      } | null;
    };
    campaign: { __typename: 'Campaign'; id: string; name: string };
  };
};

export type UpdateRewardValueMutationVariables = Exact<{
  input: UpdateRewardValueInput;
}>;

export type UpdateRewardValueMutation = {
  __typename: 'Mutation';
  updateRewardValue: {
    __typename: 'CreatorHasCampaign';
    id: string;
    rewardValue: number;
  };
};

export type PriceForCreatorHasCampaignQueryVariables = Exact<{
  creatorHasCampaignId: Scalars['UUID']['input'];
}>;

export type PriceForCreatorHasCampaignQuery = {
  __typename: 'Query';
  priceForCreatorHasCampaign: number;
};

export type MyCreatorNotificationSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type MyCreatorNotificationSettingsQuery = {
  __typename: 'Query';
  myCreatorNotificationSettings: {
    __typename: 'CreatorNotificationSettings';
    id: string;
    newMessage: Array<CreatorNotificationChannel>;
    newCampaignAvailable: Array<CreatorNotificationChannel>;
    applicationAccepted: Array<CreatorNotificationChannel>;
    applicationDeclined: Array<CreatorNotificationChannel>;
    previewAccepted: Array<CreatorNotificationChannel>;
    previewRetried: Array<CreatorNotificationChannel>;
    reportFailed: Array<CreatorNotificationChannel>;
    collaborationDiscontinued: Array<CreatorNotificationChannel>;
    campaignSuspended: Array<CreatorNotificationChannel>;
    unapplyApplicationReminder: Array<CreatorNotificationChannel>;
    submitPreviewReminder: Array<CreatorNotificationChannel>;
    submitContentReminder: Array<CreatorNotificationChannel>;
    newCampaignAvailableCategories: Array<{
      __typename: 'Category';
      id: string;
      name: string;
    }>;
  };
};

export type UpdateCreatorNotificationSettingMutationVariables = Exact<{
  input: UpdateCreatorNotificationSettingInput;
}>;

export type UpdateCreatorNotificationSettingMutation = {
  __typename: 'Mutation';
  updateCreatorNotificationSetting: {
    __typename: 'CreatorNotificationSettings';
    id: string;
    newMessage: Array<CreatorNotificationChannel>;
    newCampaignAvailable: Array<CreatorNotificationChannel>;
    applicationAccepted: Array<CreatorNotificationChannel>;
    applicationDeclined: Array<CreatorNotificationChannel>;
    previewAccepted: Array<CreatorNotificationChannel>;
    previewRetried: Array<CreatorNotificationChannel>;
    reportFailed: Array<CreatorNotificationChannel>;
    collaborationDiscontinued: Array<CreatorNotificationChannel>;
    campaignSuspended: Array<CreatorNotificationChannel>;
    unapplyApplicationReminder: Array<CreatorNotificationChannel>;
    submitPreviewReminder: Array<CreatorNotificationChannel>;
    submitContentReminder: Array<CreatorNotificationChannel>;
    newCampaignAvailableCategories: Array<{
      __typename: 'Category';
      id: string;
      name: string;
    }>;
  };
};

export type UpdateNewCampaignAvailableNotificationCategoriesMutationVariables =
  Exact<{
    input: UpdateNewCampaignAvailableNotificationCategoriesInput;
  }>;

export type UpdateNewCampaignAvailableNotificationCategoriesMutation = {
  __typename: 'Mutation';
  updateNewCampaignAvailableNotificationCategories: {
    __typename: 'CreatorNotificationSettings';
    id: string;
    newCampaignAvailableCategories: Array<{
      __typename: 'Category';
      id: string;
      name: string;
    }>;
  };
};

export type LatestInstagramMediaQueryVariables = Exact<{
  instagramChannelId: Scalars['UUID']['input'];
}>;

export type LatestInstagramMediaQuery = {
  __typename: 'Query';
  latestInstagramMedia: Array<{
    __typename: 'InstagramMedia';
    id: string;
    mediaType: InstagramMediaType;
    mediaProductType: InstagramMediaProductType;
    thumbnailUrl?: string | null;
    mediaUrl?: string | null;
    caption?: string | null;
    timestamp: string;
  }>;
};

export type LatestInstagramStoriesQueryVariables = Exact<{
  instagramChannelId: Scalars['UUID']['input'];
}>;

export type LatestInstagramStoriesQuery = {
  __typename: 'Query';
  latestInstagramStories: Array<{
    __typename: 'InstagramMedia';
    id: string;
    mediaType: InstagramMediaType;
    mediaProductType: InstagramMediaProductType;
    thumbnailUrl?: string | null;
    mediaUrl?: string | null;
    caption?: string | null;
    timestamp: string;
  }>;
};

export type AddInstagramChannelMutationVariables = Exact<{
  input: AddInstagramChannelInput;
}>;

export type AddInstagramChannelMutation = {
  __typename: 'Mutation';
  addInstagramChannel: {
    __typename: 'Creator';
    id: string;
    channels: Array<{
      __typename: 'Channel';
      id: string;
      isAccessTokenValid: boolean;
      channelableType: ChannelType;
      channelable:
        | {
            __typename: 'InstagramChannel';
            id: string;
            username: string;
            url: string;
            followersCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            mediaCount: number;
          }
        | {
            __typename: 'TiktokChannel';
            id: string;
            displayName: string;
            url: string;
            followerCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          }
        | {
            __typename: 'YoutubeChannel';
            id: string;
            title: string;
            url: string;
            subscriberCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          };
    }>;
  };
};

export type UpdateAccessTokenForInstagramChannelMutationVariables = Exact<{
  input: UpdateAccessTokenForInstagramChannelInput;
}>;

export type UpdateAccessTokenForInstagramChannelMutation = {
  __typename: 'Mutation';
  updateAccessTokenForInstagramChannel: {
    __typename: 'Creator';
    id: string;
    channels: Array<{
      __typename: 'Channel';
      id: string;
      isAccessTokenValid: boolean;
      channelableType: ChannelType;
      channelable:
        | {
            __typename: 'InstagramChannel';
            id: string;
            username: string;
            url: string;
            followersCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            mediaCount: number;
          }
        | {
            __typename: 'TiktokChannel';
            id: string;
            displayName: string;
            url: string;
            followerCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          }
        | {
            __typename: 'YoutubeChannel';
            id: string;
            title: string;
            url: string;
            subscriberCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          };
    }>;
  };
};

export type ManagerDocumentQueryVariables = Exact<{ [key: string]: never }>;

export type ManagerDocumentQuery = {
  __typename: 'Query';
  whoAmI:
    | { __typename: 'CreatorAuthenticatable' }
    | {
        __typename: 'ManagerAuthenticatable';
        id: string;
        locale: string;
        isAdmin: string;
        user: { __typename: 'Manager'; id: string; email: string };
      };
};

export type ManagersQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ManagersQuery = {
  __typename: 'Query';
  managers: {
    __typename: 'ManagerPaginator';
    data: Array<{ __typename: 'Manager'; id: string; email: string }>;
    paginatorInfo: { __typename: 'PaginatorInfo'; total: number };
  };
};

export type DeleteManagerMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteManagerMutation = {
  __typename: 'Mutation';
  deleteManager: string;
};

export type LogoutManagerMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutManagerMutation = {
  __typename: 'Mutation';
  logoutManager: string;
};

export type ImpersonateManagerMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type ImpersonateManagerMutation = {
  __typename: 'Mutation';
  impersonateManager: string;
};

export type ForgotManagerPasswordMutationVariables = Exact<{
  input: ForgotManagerPasswordInput;
}>;

export type ForgotManagerPasswordMutation = {
  __typename: 'Mutation';
  forgotManagerPassword: string;
};

export type LoginManagerMutationVariables = Exact<{
  input: LoginManagerInput;
}>;

export type LoginManagerMutation = {
  __typename: 'Mutation';
  loginManager: string;
};

export type UpdateManagerPasswordMutationVariables = Exact<{
  input: UpdateManagerPasswordInput;
}>;

export type UpdateManagerPasswordMutation = {
  __typename: 'Mutation';
  updateManagerPassword: string;
};

export type RequestVerificationOfNewManagerEmailMutationVariables = Exact<{
  input: RequestVerificationOfNewManagerEmailInput;
}>;

export type RequestVerificationOfNewManagerEmailMutation = {
  __typename: 'Mutation';
  requestVerificationOfNewManagerEmail: string;
};

export type RecoverManagerPasswordMutationVariables = Exact<{
  input: RecoverManagerPasswordInput;
}>;

export type RecoverManagerPasswordMutation = {
  __typename: 'Mutation';
  recoverManagerPassword: string;
};

export type UpdateManagerLocaleMutationVariables = Exact<{
  input: UpdateManagerLocaleInput;
}>;

export type UpdateManagerLocaleMutation = {
  __typename: 'Mutation';
  updateManagerLocale: {
    __typename: 'ManagerAuthenticatable';
    id: string;
    locale: string;
  };
};

export type VerifyManagerEmailMutationVariables = Exact<{
  input: VerifyManagerEmailInput;
}>;

export type VerifyManagerEmailMutation = {
  __typename: 'Mutation';
  verifyManagerEmail: string;
};

export type ResendManagerVerificationEmailMutationVariables = Exact<{
  input: ResendManagerVerificationEmailInput;
}>;

export type ResendManagerVerificationEmailMutation = {
  __typename: 'Mutation';
  resendManagerVerificationEmail: string;
};

export type VerifyAndUpdateManagerEmailMutationVariables = Exact<{
  input: VerifyManagerEmailInput;
}>;

export type VerifyAndUpdateManagerEmailMutation = {
  __typename: 'Mutation';
  verifyAndUpdateManagerEmail: {
    __typename: 'Manager';
    id: string;
    email: string;
  };
};

export type MessagesQueryVariables = Exact<{
  creatorHasCampaignId: Scalars['UUID']['input'];
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type MessagesQuery = {
  __typename: 'Query';
  messages: {
    __typename: 'MessagePaginator';
    data: Array<{
      __typename: 'Message';
      id: string;
      sender: MessageSender;
      text: string;
      createdAt: string;
    }>;
    paginatorInfo: {
      __typename: 'PaginatorInfo';
      currentPage: number;
      hasMorePages: boolean;
    };
  };
};

export type CreateMessageMutationVariables = Exact<{
  input: CreateMessageInput;
}>;

export type CreateMessageMutation = {
  __typename: 'Mutation';
  createMessage: {
    __typename: 'Message';
    id: string;
    sender: MessageSender;
    text: string;
    createdAt: string;
  };
};

export type SubscriptionPlansQueryVariables = Exact<{ [key: string]: never }>;

export type SubscriptionPlansQuery = {
  __typename: 'Query';
  subscriptionPlans: Array<{
    __typename: 'SubscriptionPlan';
    id: string;
    name: string;
    bookingFeePercentage: number;
    minBookingFee: number;
    maxBrandCount?: number | null;
    maxCampaignCount?: number | null;
    storageSpaceLimit: number;
    prices: Array<{
      __typename: 'SubscriptionPrice';
      id: string;
      amount: number;
      recurringInterval: RecurringIntervalPrice;
      lookupKey: SubscriptionPriceLookupKey;
    }>;
  }>;
};

export type LatestTiktokVideosQueryVariables = Exact<{
  tiktokChannelId: Scalars['UUID']['input'];
}>;

export type LatestTiktokVideosQuery = {
  __typename: 'Query';
  latestTiktokVideos: Array<{
    __typename: 'TiktokVideo';
    id: string;
    coverImageUrl: string;
    title?: string | null;
  }>;
};

export type AddTiktokChannelMutationVariables = Exact<{
  input: AddTiktokChannelInput;
}>;

export type AddTiktokChannelMutation = {
  __typename: 'Mutation';
  addTiktokChannel: {
    __typename: 'Creator';
    id: string;
    channels: Array<{
      __typename: 'Channel';
      id: string;
      isAccessTokenValid: boolean;
      channelableType: ChannelType;
      channelable:
        | {
            __typename: 'InstagramChannel';
            id: string;
            username: string;
            url: string;
            followersCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            mediaCount: number;
          }
        | {
            __typename: 'TiktokChannel';
            id: string;
            displayName: string;
            url: string;
            followerCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          }
        | {
            __typename: 'YoutubeChannel';
            id: string;
            title: string;
            url: string;
            subscriberCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          };
    }>;
  };
};

export type UpdateAccessTokenForTiktokChannelMutationVariables = Exact<{
  input: UpdateAccessTokenForTiktokChannelInput;
}>;

export type UpdateAccessTokenForTiktokChannelMutation = {
  __typename: 'Mutation';
  updateAccessTokenForTiktokChannel: {
    __typename: 'Creator';
    id: string;
    channels: Array<{
      __typename: 'Channel';
      id: string;
      isAccessTokenValid: boolean;
      channelableType: ChannelType;
      channelable:
        | {
            __typename: 'InstagramChannel';
            id: string;
            username: string;
            url: string;
            followersCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            mediaCount: number;
          }
        | {
            __typename: 'TiktokChannel';
            id: string;
            displayName: string;
            url: string;
            followerCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          }
        | {
            __typename: 'YoutubeChannel';
            id: string;
            title: string;
            url: string;
            subscriberCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          };
    }>;
  };
};

export type LatestYoutubeVideosQueryVariables = Exact<{
  youtubeChannelId: Scalars['UUID']['input'];
}>;

export type LatestYoutubeVideosQuery = {
  __typename: 'Query';
  latestYoutubeVideos: Array<{
    __typename: 'YoutubeVideo';
    id: string;
    coverImageUrl: string;
    title: string;
  }>;
};

export type AddYoutubeChannelMutationVariables = Exact<{
  input: AddYoutubeChannelInput;
}>;

export type AddYoutubeChannelMutation = {
  __typename: 'Mutation';
  addYoutubeChannel: {
    __typename: 'Creator';
    id: string;
    channels: Array<{
      __typename: 'Channel';
      id: string;
      isAccessTokenValid: boolean;
      channelableType: ChannelType;
      channelable:
        | {
            __typename: 'InstagramChannel';
            id: string;
            username: string;
            url: string;
            followersCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            mediaCount: number;
          }
        | {
            __typename: 'TiktokChannel';
            id: string;
            displayName: string;
            url: string;
            followerCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          }
        | {
            __typename: 'YoutubeChannel';
            id: string;
            title: string;
            url: string;
            subscriberCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          };
    }>;
  };
};

export type UpdateAccessTokenForYoutubeChannelMutationVariables = Exact<{
  input: UpdateAccessTokenForYoutubeChannelInput;
}>;

export type UpdateAccessTokenForYoutubeChannelMutation = {
  __typename: 'Mutation';
  updateAccessTokenForYoutubeChannel: {
    __typename: 'Creator';
    id: string;
    channels: Array<{
      __typename: 'Channel';
      id: string;
      isAccessTokenValid: boolean;
      channelableType: ChannelType;
      channelable:
        | {
            __typename: 'InstagramChannel';
            id: string;
            username: string;
            url: string;
            followersCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            mediaCount: number;
          }
        | {
            __typename: 'TiktokChannel';
            id: string;
            displayName: string;
            url: string;
            followerCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          }
        | {
            __typename: 'YoutubeChannel';
            id: string;
            title: string;
            url: string;
            subscriberCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          };
    }>;
  };
};

export type CreatorAuthenticatableFragment = {
  __typename: 'CreatorAuthenticatable';
  id: string;
  locale: string;
  hasSSOLogin: boolean;
};

export type ManagerAuthenticatableFragment = {
  __typename: 'ManagerAuthenticatable';
  id: string;
  locale: string;
  isAdmin: string;
};

export type BrandFragment = {
  __typename: 'Brand';
  id: string;
  name: string;
  logo: { __typename: 'Download'; uri: string; hash?: string | null };
};

export type CampaignFragment = {
  __typename: 'Campaign';
  id: string;
  name: string;
  description: string;
  status: CampaignStatus;
  rewardType: CampaignRewardType;
  fixedMoneyRewardValue?: number | null;
  applyUntil?: string | null;
  address?: string | null;
  includeContentFileOnSubmission: boolean;
  taskItems: Array<{
    __typename: 'TaskItem';
    id: string;
    type: TaskItemType;
    taskable:
      | { __typename: 'FreeTextTask'; id: string; description: string }
      | { __typename: 'HashtagTask'; id: string; hashtags: Array<string> }
      | { __typename: 'WebsiteTask'; id: string; url: string };
  }>;
  headerImage: { __typename: 'Download'; uri: string; hash?: string | null };
  brand: {
    __typename: 'Brand';
    id: string;
    name: string;
    logo: { __typename: 'Download'; uri: string; hash?: string | null };
  };
  category: { __typename: 'Category'; id: string; name: string };
  gifts: Array<{
    __typename: 'CampaignGift';
    id: string;
    type: CampaignGiftType;
    name: string;
    description: string;
  }>;
  questions: Array<{ __typename: 'Question'; id: string; question: string }>;
  typeable:
    | {
        __typename: 'InstagramCampaign';
        id: string;
        channelType: ChannelType;
        instagramContentType: InstagramContentType;
      }
    | {
        __typename: 'TiktokCampaign';
        id: string;
        channelType: ChannelType;
        tiktokContentType: TiktokContentType;
      }
    | {
        __typename: 'YoutubeCampaign';
        id: string;
        channelType: ChannelType;
        youtubeContentType: YoutubeContentType;
      };
};

export type CampaignTypeableFragment = {
  __typename: 'Campaign';
  typeable:
    | {
        __typename: 'InstagramCampaign';
        id: string;
        channelType: ChannelType;
        instagramContentType: InstagramContentType;
      }
    | {
        __typename: 'TiktokCampaign';
        id: string;
        channelType: ChannelType;
        tiktokContentType: TiktokContentType;
      }
    | {
        __typename: 'YoutubeCampaign';
        id: string;
        channelType: ChannelType;
        youtubeContentType: YoutubeContentType;
      };
};

export type CampaignStatisticsFragment = {
  __typename: 'CampaignStatistics';
  id: string;
  applications: number;
  applicationsAccepted: number;
  previewsAccepted: number;
  contentSubmissions: number;
  applicationsToReview: number;
  previewsToReview: number;
};

export type ChannelFragment = {
  __typename: 'Channel';
  id: string;
  isAccessTokenValid: boolean;
  channelableType: ChannelType;
  channelable:
    | {
        __typename: 'InstagramChannel';
        id: string;
        username: string;
        url: string;
        followersCount: number;
        averageEngagementRate?: number | null;
        averageReach?: number | null;
        mediaCount: number;
      }
    | {
        __typename: 'TiktokChannel';
        id: string;
        displayName: string;
        url: string;
        followerCount: number;
        averageEngagementRate?: number | null;
        averageReach?: number | null;
        videoCount: number;
      }
    | {
        __typename: 'YoutubeChannel';
        id: string;
        title: string;
        url: string;
        subscriberCount: number;
        averageEngagementRate?: number | null;
        averageReach?: number | null;
        videoCount: number;
      };
};

export type InstagramChannelFragment = {
  __typename: 'InstagramChannel';
  id: string;
  username: string;
  url: string;
  followersCount: number;
  averageEngagementRate?: number | null;
  averageReach?: number | null;
  mediaCount: number;
};

export type TiktokChannelFragment = {
  __typename: 'TiktokChannel';
  id: string;
  displayName: string;
  url: string;
  followerCount: number;
  averageEngagementRate?: number | null;
  averageReach?: number | null;
  videoCount: number;
};

export type YoutubeChannelFragment = {
  __typename: 'YoutubeChannel';
  id: string;
  title: string;
  url: string;
  subscriberCount: number;
  averageEngagementRate?: number | null;
  averageReach?: number | null;
  videoCount: number;
};

export type CreatorFragment = {
  __typename: 'Creator';
  id: string;
  username: string;
  description?: string | null;
  isUnreliable: boolean;
  profilePicture?: {
    __typename: 'Download';
    uri: string;
    hash?: string | null;
  } | null;
};

export type CreatorDeliveryAddressTemplateFragment = {
  __typename: 'CreatorDeliveryAddressTemplate';
  id: string;
  recipientName: string;
  streetAddressLine1: string;
  streetAddressLine2?: string | null;
  postcode: string;
  city: string;
  country: string;
};

export type CreatorNotificationSettingsFragment = {
  __typename: 'CreatorNotificationSettings';
  id: string;
  newMessage: Array<CreatorNotificationChannel>;
  newCampaignAvailable: Array<CreatorNotificationChannel>;
  applicationAccepted: Array<CreatorNotificationChannel>;
  applicationDeclined: Array<CreatorNotificationChannel>;
  previewAccepted: Array<CreatorNotificationChannel>;
  previewRetried: Array<CreatorNotificationChannel>;
  reportFailed: Array<CreatorNotificationChannel>;
  collaborationDiscontinued: Array<CreatorNotificationChannel>;
  campaignSuspended: Array<CreatorNotificationChannel>;
  unapplyApplicationReminder: Array<CreatorNotificationChannel>;
  submitPreviewReminder: Array<CreatorNotificationChannel>;
  submitContentReminder: Array<CreatorNotificationChannel>;
  newCampaignAvailableCategories: Array<{
    __typename: 'Category';
    id: string;
    name: string;
  }>;
};

export type InstagramMediaFragment = {
  __typename: 'InstagramMedia';
  id: string;
  mediaType: InstagramMediaType;
  mediaProductType: InstagramMediaProductType;
  thumbnailUrl?: string | null;
  mediaUrl?: string | null;
  caption?: string | null;
  timestamp: string;
};

export type ManagerFragment = {
  __typename: 'Manager';
  id: string;
  email: string;
};

export const CreatorAuthenticatableFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creatorAuthenticatable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreatorAuthenticatable' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSSOLogin' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatorAuthenticatableFragment, unknown>;
export const ManagerAuthenticatableFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'managerAuthenticatable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerAuthenticatable' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdmin' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ManagerAuthenticatableFragment, unknown>;
export const CampaignTypeableFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignTypeableFragment, unknown>;
export const BrandFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BrandFragment, unknown>;
export const CampaignFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rewardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fixedMoneyRewardValue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'applyUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'includeContentFileOnSubmission' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'campaignTypeable' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskable' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'WebsiteTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'HashtagTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hashtags' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FreeTextTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headerImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'brand' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gifts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignFragment, unknown>;
export const CampaignStatisticsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignStatistics' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignStatistics' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applications' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationsAccepted' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'previewsAccepted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentSubmissions' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationsToReview' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'previewsToReview' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignStatisticsFragment, unknown>;
export const InstagramChannelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstagramChannelFragment, unknown>;
export const TiktokChannelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TiktokChannelFragment, unknown>;
export const YoutubeChannelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<YoutubeChannelFragment, unknown>;
export const ChannelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChannelFragment, unknown>;
export const CreatorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creator' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Creator' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUnreliable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatorFragment, unknown>;
export const CreatorDeliveryAddressTemplateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creatorDeliveryAddressTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreatorDeliveryAddressTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLine1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLine2' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatorDeliveryAddressTemplateFragment, unknown>;
export const CreatorNotificationSettingsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creatorNotificationSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreatorNotificationSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newMessage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newCampaignAvailable' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newCampaignAvailableCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationAccepted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationDeclined' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'previewAccepted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previewRetried' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportFailed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collaborationDiscontinued' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignSuspended' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unapplyApplicationReminder' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitPreviewReminder' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitContentReminder' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatorNotificationSettingsFragment, unknown>;
export const InstagramMediaFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaProductType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstagramMediaFragment, unknown>;
export const ManagerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'manager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Manager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ManagerFragment, unknown>;
export const MyBrandsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myBrands' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myBrands' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'brand' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyBrandsQuery, MyBrandsQueryVariables>;
export const BrandDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'brand' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'brand' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BrandQuery, BrandQueryVariables>;
export const CreateBrandDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createBrand' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateBrandInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBrand' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'brand' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateBrandMutation, CreateBrandMutationVariables>;
export const UpdateBrandDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBrand' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateBrandInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBrand' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'brand' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateBrandMutation, UpdateBrandMutationVariables>;
export const UpdateBrandLogoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBrandLogo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateBrandLogoInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBrandLogo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'brand' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBrandLogoMutation,
  UpdateBrandLogoMutationVariables
>;
export const CampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'campaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'campaign' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rewardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fixedMoneyRewardValue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'applyUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'includeContentFileOnSubmission' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'campaignTypeable' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskable' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'WebsiteTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'HashtagTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hashtags' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FreeTextTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headerImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'brand' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gifts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignQuery, CampaignQueryVariables>;
export const DynamicMoneyRewardValueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'dynamicMoneyRewardValue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'campaignId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dynamicMoneyRewardValue' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'campaignId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DynamicMoneyRewardValueQuery,
  DynamicMoneyRewardValueQueryVariables
>;
export const CampaignYouAreApplyingForDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'campaignYouAreApplyingFor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'headerImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'campaignTypeable' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rewardType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fixedMoneyRewardValue' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'question' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brand' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'brand' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'gifts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CampaignYouAreApplyingForQuery,
  CampaignYouAreApplyingForQueryVariables
>;
export const CampaignGiftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'campaignGift' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'campaignId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'campaignId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'gifts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignGiftQuery, CampaignGiftQueryVariables>;
export const DiscoverCampaignsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'discoverCampaigns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DiscoverCampaignsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discoverCampaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'campaignTypeable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'headerImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uri' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hash' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'brand' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'brand' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasMorePages' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DiscoverCampaignsQuery,
  DiscoverCampaignsQueryVariables
>;
export const CampaignBriefingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'campaignBriefing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'campaign' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rewardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fixedMoneyRewardValue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'applyUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'includeContentFileOnSubmission' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'campaignTypeable' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskable' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'WebsiteTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'HashtagTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hashtags' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FreeTextTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headerImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'brand' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gifts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CampaignBriefingQuery,
  CampaignBriefingQueryVariables
>;
export const PublishCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'publishCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PublishCampaignMutation,
  PublishCampaignMutationVariables
>;
export const DeleteCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCampaignMutation,
  DeleteCampaignMutationVariables
>;
export const MyCampaignReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myCampaignReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MyCampaignReportsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myCampaignReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaign' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reportable' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'InstagramReelCampaignReport',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'completedCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'followerCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cpv' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'rewardValue',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'InstagramPostCampaignReport',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'completedCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'followerCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cpv' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'rewardValue',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'InstagramStoryCampaignReport',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'completedCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'followerCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cpv' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'rewardValue',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'TiktokVideoCampaignReport',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'completedCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'followerCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cpv' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'rewardValue',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'YoutubeVideoCampaignReport',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'completedCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subscriberCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cpv' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'rewardValue',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasMorePages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MyCampaignReportsQuery,
  MyCampaignReportsQueryVariables
>;
export const MyCampaignsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myCampaigns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MyCampaignsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myCampaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaign' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'headerImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uri' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hash' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'brand' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'brand' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'campaignTypeable' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statistics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'applicationsToReview',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'previewsToReview' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasMorePages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyCampaignsQuery, MyCampaignsQueryVariables>;
export const CreateCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCampaignInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'campaign' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rewardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fixedMoneyRewardValue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'applyUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'includeContentFileOnSubmission' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'campaignTypeable' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskable' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'WebsiteTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'HashtagTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hashtags' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FreeTextTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headerImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'brand' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gifts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCampaignMutation,
  CreateCampaignMutationVariables
>;
export const UpdateCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCampaignInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'campaign' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rewardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fixedMoneyRewardValue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'applyUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'includeContentFileOnSubmission' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'campaignTypeable' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskable' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'WebsiteTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'HashtagTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hashtags' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FreeTextTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headerImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'brand' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gifts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCampaignMutation,
  UpdateCampaignMutationVariables
>;
export const UpdateCampaignHeaderImageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCampaignHeaderImage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCampaignHeaderImageInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCampaignHeaderImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'headerImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCampaignHeaderImageMutation,
  UpdateCampaignHeaderImageMutationVariables
>;
export const CompleteCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'completeCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completeCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompleteCampaignMutation,
  CompleteCampaignMutationVariables
>;
export const CampaignPreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'campaignPreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applyUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'includeContentFileOnSubmission',
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rewardType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fixedMoneyRewardValue' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'campaignTypeable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'headerImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brand' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'brand' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'gifts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taskable' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'WebsiteTask' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'HashtagTask' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hashtags' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'FreeTextTask' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CampaignPreviewQuery,
  CampaignPreviewQueryVariables
>;
export const InstagramPostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'instagramPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applyUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rewardType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fixedMoneyRewardValue' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'campaignTypeable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'headerImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brand' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'gifts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstagramPostQuery, InstagramPostQueryVariables>;
export const DiscoverCampaignsForSitemapDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'discoverCampaignsForSitemap' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DiscoverCampaignsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discoverCampaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DiscoverCampaignsForSitemapQuery,
  DiscoverCampaignsForSitemapQueryVariables
>;
export const DiscoverCampaignsForInstagramPostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'discoverCampaignsForInstagramPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DiscoverCampaignsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discoverCampaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DiscoverCampaignsForInstagramPostQuery,
  DiscoverCampaignsForInstagramPostQueryVariables
>;
export const PublicDiscoverCampaignsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'publicDiscoverCampaigns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DiscoverCampaignsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discoverCampaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'campaignTypeable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'headerImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uri' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hash' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'brand' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'brand' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PublicDiscoverCampaignsQuery,
  PublicDiscoverCampaignsQueryVariables
>;
export const CampaignReportForCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'campaignReportForCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'campaignId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignReportForCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'campaignId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'InstagramReelCampaignReport',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'followerCount' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'views' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'likes' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comments' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shares' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'reach' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'engagementRate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'cpv' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rewardValue' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'creatorHasCampaignReports',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'creatorHasCampaign',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creator' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'username',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'followerCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'views' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'likes' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'comments' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shares' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reach' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'engagementRate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cpv' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rewardValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastFetchedAt' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'InstagramPostCampaignReport',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'followerCount' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'views' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'saves' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'engagementRate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'cpv' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rewardValue' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'creatorHasCampaignReports',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'creatorHasCampaign',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creator' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'username',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'followerCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'views' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'saves' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'engagementRate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cpv' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rewardValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastFetchedAt' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'InstagramStoryCampaignReport',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'followerCount' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'views' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'engagementRate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'cpv' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rewardValue' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'creatorHasCampaignReports',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'creatorHasCampaign',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creator' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'username',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'followerCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'views' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'engagementRate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cpv' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rewardValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastFetchedAt' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokVideoCampaignReport' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'followerCount' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'views' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'likes' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comments' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shares' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'engagementRate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'cpv' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rewardValue' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'creatorHasCampaignReports',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'creatorHasCampaign',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creator' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'username',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'followerCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'views' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'likes' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'comments' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shares' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'engagementRate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cpv' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rewardValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastFetchedAt' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeVideoCampaignReport' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subscriberCount' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'views' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'likes' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comments' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'favorites' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'engagementRate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'cpv' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rewardValue' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'creatorHasCampaignReports',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'creatorHasCampaign',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creator' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'username',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subscriberCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'views' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'likes' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'comments' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'favorites' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'engagementRate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cpv' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rewardValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastFetchedAt' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CampaignReportForCampaignQuery,
  CampaignReportForCampaignQueryVariables
>;
export const CampaignStatisticsForCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'campaignStatisticsForCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'campaignId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignStatisticsForCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'campaignId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'campaignStatistics' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignStatistics' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignStatistics' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applications' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationsAccepted' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'previewsAccepted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentSubmissions' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationsToReview' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'previewsToReview' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CampaignStatisticsForCampaignQuery,
  CampaignStatisticsForCampaignQueryVariables
>;
export const CategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'categories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CategoriesQuery, CategoriesQueryVariables>;
export const ChannelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'channels' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'whoAmI' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreatorAuthenticatable' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'channels' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'channel' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChannelsQuery, ChannelsQueryVariables>;
export const DeleteChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'channel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteChannelMutation,
  DeleteChannelMutationVariables
>;
export const StripeCustomerPortalUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'stripeCustomerPortalUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StripeCustomerPortalUrlInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeCustomerPortalUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StripeCustomerPortalUrlQuery,
  StripeCustomerPortalUrlQueryVariables
>;
export const RegisterCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'registerCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RegisterCompanyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registerCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegisterCompanyMutation,
  RegisterCompanyMutationVariables
>;
export const CheckoutSubscriptionForCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'checkoutSubscriptionForCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CheckoutSubscriptionForCompanyInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkoutSubscriptionForCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutSubscriptionForCompanyMutation,
  CheckoutSubscriptionForCompanyMutationVariables
>;
export const BrandUsageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'brandUsage' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentBrandCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentSubscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subscriptionPlan' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'maxBrandCount' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BrandUsageQuery, BrandUsageQueryVariables>;
export const CampaignUsageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'campaignUsage' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentActiveCampaignCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentSubscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subscriptionPlan' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'maxCampaignCount' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignUsageQuery, CampaignUsageQueryVariables>;
export const StorageSpaceUsageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'storageSpaceUsage' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'usedStorageSpace' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentSubscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subscriptionPlan' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'storageSpaceLimit',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StorageSpaceUsageQuery,
  StorageSpaceUsageQueryVariables
>;
export const MyCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myCompany' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentActiveCampaignCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentBrandCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'usedStorageSpace' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentSubscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subscriptionPlan' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'bookingFeePercentage',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minBookingFee' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'maxCampaignCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'maxBrandCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'storageSpaceLimit',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyCompanyQuery, MyCompanyQueryVariables>;
export const CreatorAuthenticatableContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'creatorAuthenticatableContext' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'whoAmI' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreatorAuthenticatable' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'creatorAuthenticatable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'creator' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'channels' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'channel' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creatorAuthenticatable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreatorAuthenticatable' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSSOLogin' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creator' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Creator' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUnreliable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatorAuthenticatableContextQuery,
  CreatorAuthenticatableContextQueryVariables
>;
export const CreatorProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'creatorProfile' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'whoAmI' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreatorAuthenticatable' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'creatorAuthenticatable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'creator' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'channels' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'channel' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creatorAuthenticatable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreatorAuthenticatable' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSSOLogin' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creator' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Creator' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUnreliable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatorProfileQuery, CreatorProfileQueryVariables>;
export const CreatorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'creators' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creators' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'username' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isUnreliable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'channelableType' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatorsQuery, CreatorsQueryVariables>;
export const RegisterCreatorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'registerCreator' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RegisterCreatorInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registerCreator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'creatorAuthenticatable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'creator' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creatorAuthenticatable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreatorAuthenticatable' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSSOLogin' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creator' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Creator' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUnreliable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegisterCreatorMutation,
  RegisterCreatorMutationVariables
>;
export const SocialRegisterCreatorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'socialRegisterCreator' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SocialRegisterCreatorInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialRegisterCreator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SocialRegisterCreatorMutation,
  SocialRegisterCreatorMutationVariables
>;
export const DeactivateCreatorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deactivateCreator' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'deactivateCreator' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeactivateCreatorMutation,
  DeactivateCreatorMutationVariables
>;
export const ForgotCreatorPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'forgotCreatorPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ForgotCreatorPasswordInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forgotCreatorPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ForgotCreatorPasswordMutation,
  ForgotCreatorPasswordMutationVariables
>;
export const RecoverCreatorPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'recoverCreatorPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RecoverCreatorPasswordInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recoverCreatorPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecoverCreatorPasswordMutation,
  RecoverCreatorPasswordMutationVariables
>;
export const VerifyCreatorEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'verifyCreatorEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'VerifyCreatorEmailInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyCreatorEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifyCreatorEmailMutation,
  VerifyCreatorEmailMutationVariables
>;
export const ResendCreatorVerificationEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resendCreatorVerificationEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ResendCreatorVerificationEmailInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resendCreatorVerificationEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResendCreatorVerificationEmailMutation,
  ResendCreatorVerificationEmailMutationVariables
>;
export const RequestVerificationOfNewCreatorEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'requestVerificationOfNewCreatorEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'RequestVerificationOfNewCreatorEmailInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'requestVerificationOfNewCreatorEmail',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestVerificationOfNewCreatorEmailMutation,
  RequestVerificationOfNewCreatorEmailMutationVariables
>;
export const VerifyAndUpdateCreatorEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'verifyAndUpdateCreatorEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'VerifyCreatorEmailInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyAndUpdateCreatorEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifyAndUpdateCreatorEmailMutation,
  VerifyAndUpdateCreatorEmailMutationVariables
>;
export const UpdateCreatorProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCreatorProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCreatorProfileInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCreatorProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCreatorProfileMutation,
  UpdateCreatorProfileMutationVariables
>;
export const UpdateCreatorLocaleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCreatorLocale' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCreatorLocaleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCreatorLocale' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCreatorLocaleMutation,
  UpdateCreatorLocaleMutationVariables
>;
export const UpdateCreatorPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCreatorPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCreatorPasswordInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCreatorPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCreatorPasswordMutation,
  UpdateCreatorPasswordMutationVariables
>;
export const UploadCreatorProfilePictureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'uploadCreatorProfilePicture' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UploadCreatorProfilePictureInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadCreatorProfilePicture' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UploadCreatorProfilePictureMutation,
  UploadCreatorProfilePictureMutationVariables
>;
export const LoginCreatorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'loginCreator' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoginCreatorInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loginCreator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoginCreatorMutation,
  LoginCreatorMutationVariables
>;
export const LogoutCreatorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'logoutCreator' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'logoutCreator' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LogoutCreatorMutation,
  LogoutCreatorMutationVariables
>;
export const SocialLoginCreatorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'socialLoginCreator' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SocialLoginCreatorInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialLoginCreator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SocialLoginCreatorMutation,
  SocialLoginCreatorMutationVariables
>;
export const SetCreatorExpoPushTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setCreatorExpoPushToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SetCreatorExpoPushTokenInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setCreatorExpoPushToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetCreatorExpoPushTokenMutation,
  SetCreatorExpoPushTokenMutationVariables
>;
export const ImpersonateCreatorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'impersonateCreator' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'impersonateCreator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ImpersonateCreatorMutation,
  ImpersonateCreatorMutationVariables
>;
export const MyCreatorDeliveryAddressTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myCreatorDeliveryAddressTemplates' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myCreatorDeliveryAddressTemplates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'creatorDeliveryAddressTemplate',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creatorDeliveryAddressTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreatorDeliveryAddressTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLine1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLine2' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MyCreatorDeliveryAddressTemplatesQuery,
  MyCreatorDeliveryAddressTemplatesQueryVariables
>;
export const CreateCreatorDeliveryAddressTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCreatorDeliveryAddressTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreateCreatorDeliveryAddressTemplateInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'createCreatorDeliveryAddressTemplate',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'creatorDeliveryAddressTemplate',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creatorDeliveryAddressTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreatorDeliveryAddressTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLine1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLine2' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCreatorDeliveryAddressTemplateMutation,
  CreateCreatorDeliveryAddressTemplateMutationVariables
>;
export const UpdateCreatorDeliveryAddressTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCreatorDeliveryAddressTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateCreatorDeliveryAddressTemplateInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'updateCreatorDeliveryAddressTemplate',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'creatorDeliveryAddressTemplate',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creatorDeliveryAddressTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreatorDeliveryAddressTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLine1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLine2' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCreatorDeliveryAddressTemplateMutation,
  UpdateCreatorDeliveryAddressTemplateMutationVariables
>;
export const DeleteCreatorDeliveryAddressTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCreatorDeliveryAddressTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'deleteCreatorDeliveryAddressTemplate',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCreatorDeliveryAddressTemplateMutation,
  DeleteCreatorDeliveryAddressTemplateMutationVariables
>;
export const ApplyForCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'applyForCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ApplyForCampaignInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applyForCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rewardValue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaign' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplyForCampaignMutation,
  ApplyForCampaignMutationVariables
>;
export const UnapplyFromCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'unapplyFromCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'creatorHasCampaignId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unapplyFromCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creatorHasCampaignId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'creatorHasCampaignId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UnapplyFromCampaignMutation,
  UnapplyFromCampaignMutationVariables
>;
export const MyCreatorHasCampaignsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myCreatorHasCampaigns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MyCreatorHasCampaignsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myCreatorHasCampaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaign' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'headerImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uri' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hash' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'brand' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'logo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uri' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'hash' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasMorePages' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MyCreatorHasCampaignsQuery,
  MyCreatorHasCampaignsQueryVariables
>;
export const MyCreatorHasCampaignForCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myCreatorHasCampaignForCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'campaignId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myCreatorHasCampaignForCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'campaignId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discontinuedReason' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rewardValue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'answers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'answer' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'question' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'question' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewRevisions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'retriedReason' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'channel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MyCreatorHasCampaignForCampaignQuery,
  MyCreatorHasCampaignForCampaignQueryVariables
>;
export const CampaignGiftCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'campaignGiftCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'campaignId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myCreatorHasCampaignForCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'campaignId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaignGiftCodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gift' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CampaignGiftCodeQuery,
  CampaignGiftCodeQueryVariables
>;
export const SubmitPreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'submitPreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SubmitPreviewInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitPreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewRevisions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitPreviewMutation,
  SubmitPreviewMutationVariables
>;
export const SubmitContentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'submitContent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SubmitContentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitContent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitContentMutation,
  SubmitContentMutationVariables
>;
export const CreatorsOfCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'creatorsOfCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatorsOfCampaignInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creatorsOfCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rewardValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channel' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'channel' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'username' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isUnreliable' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profilePicture' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uri' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hash' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasMorePages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatorsOfCampaignQuery,
  CreatorsOfCampaignQueryVariables
>;
export const CreatorHasCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'creatorHasCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creatorHasCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rewardValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discontinuedReason' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'contentUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rewardValue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'channel' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelable' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'InstagramChannel',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'ageFollowerDemographics',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'age' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'percentage',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'countryFollowerDemographics',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'country',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'percentage',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'genderFollowerDemographics',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'gender',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'percentage',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaignGiftCodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gift' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'answers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'answer' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'question' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'question' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipientName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'streetAddressLine1' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'streetAddressLine2' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'postcode' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewRevisions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'retriedReason' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'username' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isUnreliable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uri' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hash' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaign' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'headerImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uri' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hash' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'includeContentFileOnSubmission',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'campaignTypeable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gifts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'question' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatorHasCampaignQuery,
  CreatorHasCampaignQueryVariables
>;
export const AcceptCampaignApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'acceptCampaignApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AcceptCampaignApplicationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptCampaignApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaignGiftCodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gift' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptCampaignApplicationMutation,
  AcceptCampaignApplicationMutationVariables
>;
export const DeclineCampaignApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'declineCampaignApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'creatorHasCampaignId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'declineCampaignApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creatorHasCampaignId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'creatorHasCampaignId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeclineCampaignApplicationMutation,
  DeclineCampaignApplicationMutationVariables
>;
export const AcceptPreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'acceptPreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'creatorHasCampaignId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptPreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creatorHasCampaignId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'creatorHasCampaignId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptPreviewMutation,
  AcceptPreviewMutationVariables
>;
export const RetryPreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'retryPreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RetryPreviewInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'retryPreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewRevisions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'retriedReason' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RetryPreviewMutation,
  RetryPreviewMutationVariables
>;
export const PreviewFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'previewFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PreviewFileQuery, PreviewFileQueryVariables>;
export const CreatorHasCampaignContentFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'creatorHasCampaignContentFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creatorHasCampaignContentFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatorHasCampaignContentFileQuery,
  CreatorHasCampaignContentFileQueryVariables
>;
export const UpdateApplyUntilDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateApplyUntil' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateApplyUntilInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateApplyUntil' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applyUntil' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateApplyUntilMutation,
  UpdateApplyUntilMutationVariables
>;
export const CreatorHasCampaignMessageHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'creatorHasCampaignMessageHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creatorHasCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'username' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hash' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uri' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaign' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatorHasCampaignMessageHeaderQuery,
  CreatorHasCampaignMessageHeaderQueryVariables
>;
export const UpdateRewardValueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateRewardValue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateRewardValueInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRewardValue' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rewardValue' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateRewardValueMutation,
  UpdateRewardValueMutationVariables
>;
export const PriceForCreatorHasCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'priceForCreatorHasCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'creatorHasCampaignId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceForCreatorHasCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creatorHasCampaignId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'creatorHasCampaignId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PriceForCreatorHasCampaignQuery,
  PriceForCreatorHasCampaignQueryVariables
>;
export const MyCreatorNotificationSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myCreatorNotificationSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myCreatorNotificationSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'creatorNotificationSettings' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creatorNotificationSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreatorNotificationSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newMessage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newCampaignAvailable' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newCampaignAvailableCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationAccepted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationDeclined' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'previewAccepted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previewRetried' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportFailed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collaborationDiscontinued' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignSuspended' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unapplyApplicationReminder' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitPreviewReminder' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitContentReminder' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MyCreatorNotificationSettingsQuery,
  MyCreatorNotificationSettingsQueryVariables
>;
export const UpdateCreatorNotificationSettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCreatorNotificationSetting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateCreatorNotificationSettingInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCreatorNotificationSetting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'creatorNotificationSettings' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creatorNotificationSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreatorNotificationSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newMessage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newCampaignAvailable' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newCampaignAvailableCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationAccepted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationDeclined' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'previewAccepted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previewRetried' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportFailed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collaborationDiscontinued' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignSuspended' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unapplyApplicationReminder' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitPreviewReminder' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitContentReminder' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCreatorNotificationSettingMutation,
  UpdateCreatorNotificationSettingMutationVariables
>;
export const UpdateNewCampaignAvailableNotificationCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'updateNewCampaignAvailableNotificationCategories',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateNewCampaignAvailableNotificationCategoriesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'updateNewCampaignAvailableNotificationCategories',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'newCampaignAvailableCategories',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNewCampaignAvailableNotificationCategoriesMutation,
  UpdateNewCampaignAvailableNotificationCategoriesMutationVariables
>;
export const LatestInstagramMediaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'latestInstagramMedia' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'instagramChannelId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestInstagramMedia' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'instagramChannelId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'instagramChannelId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaProductType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LatestInstagramMediaQuery,
  LatestInstagramMediaQueryVariables
>;
export const LatestInstagramStoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'latestInstagramStories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'instagramChannelId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestInstagramStories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'instagramChannelId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'instagramChannelId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaProductType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LatestInstagramStoriesQuery,
  LatestInstagramStoriesQueryVariables
>;
export const AddInstagramChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addInstagramChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddInstagramChannelInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addInstagramChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'channel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddInstagramChannelMutation,
  AddInstagramChannelMutationVariables
>;
export const UpdateAccessTokenForInstagramChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAccessTokenForInstagramChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateAccessTokenForInstagramChannelInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'updateAccessTokenForInstagramChannel',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'channel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAccessTokenForInstagramChannelMutation,
  UpdateAccessTokenForInstagramChannelMutationVariables
>;
export const ManagerDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'managerDocument' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'whoAmI' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ManagerAuthenticatable' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'managerAuthenticatable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'manager' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'managerAuthenticatable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerAuthenticatable' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdmin' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'manager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Manager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ManagerDocumentQuery,
  ManagerDocumentQueryVariables
>;
export const ManagersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'managers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'manager' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'manager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Manager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ManagersQuery, ManagersQueryVariables>;
export const DeleteManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteManager' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'deleteManager' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteManagerMutation,
  DeleteManagerMutationVariables
>;
export const LogoutManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'logoutManager' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'logoutManager' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LogoutManagerMutation,
  LogoutManagerMutationVariables
>;
export const ImpersonateManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'impersonateManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'impersonateManager' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ImpersonateManagerMutation,
  ImpersonateManagerMutationVariables
>;
export const ForgotManagerPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'forgotManagerPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ForgotManagerPasswordInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forgotManagerPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ForgotManagerPasswordMutation,
  ForgotManagerPasswordMutationVariables
>;
export const LoginManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'loginManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoginManagerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loginManager' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoginManagerMutation,
  LoginManagerMutationVariables
>;
export const UpdateManagerPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateManagerPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateManagerPasswordInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateManagerPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateManagerPasswordMutation,
  UpdateManagerPasswordMutationVariables
>;
export const RequestVerificationOfNewManagerEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'requestVerificationOfNewManagerEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'RequestVerificationOfNewManagerEmailInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'requestVerificationOfNewManagerEmail',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestVerificationOfNewManagerEmailMutation,
  RequestVerificationOfNewManagerEmailMutationVariables
>;
export const RecoverManagerPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'recoverManagerPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RecoverManagerPasswordInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recoverManagerPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecoverManagerPasswordMutation,
  RecoverManagerPasswordMutationVariables
>;
export const UpdateManagerLocaleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateManagerLocale' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateManagerLocaleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateManagerLocale' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateManagerLocaleMutation,
  UpdateManagerLocaleMutationVariables
>;
export const VerifyManagerEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'verifyManagerEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'VerifyManagerEmailInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyManagerEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifyManagerEmailMutation,
  VerifyManagerEmailMutationVariables
>;
export const ResendManagerVerificationEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resendManagerVerificationEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ResendManagerVerificationEmailInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resendManagerVerificationEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResendManagerVerificationEmailMutation,
  ResendManagerVerificationEmailMutationVariables
>;
export const VerifyAndUpdateManagerEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'verifyAndUpdateManagerEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'VerifyManagerEmailInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyAndUpdateManagerEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'manager' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'manager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Manager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifyAndUpdateManagerEmailMutation,
  VerifyAndUpdateManagerEmailMutationVariables
>;
export const MessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'messages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'creatorHasCampaignId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creatorHasCampaignId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'creatorHasCampaignId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sender' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasMorePages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessagesQuery, MessagesQueryVariables>;
export const CreateMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateMessageInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMessageMutation,
  CreateMessageMutationVariables
>;
export const SubscriptionPlansDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'subscriptionPlans' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionPlans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurringInterval' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lookupKey' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookingFeePercentage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minBookingFee' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maxBrandCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maxCampaignCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storageSpaceLimit' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubscriptionPlansQuery,
  SubscriptionPlansQueryVariables
>;
export const LatestTiktokVideosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'latestTiktokVideos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tiktokChannelId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestTiktokVideos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tiktokChannelId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tiktokChannelId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'coverImageUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LatestTiktokVideosQuery,
  LatestTiktokVideosQueryVariables
>;
export const AddTiktokChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addTiktokChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddTiktokChannelInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addTiktokChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'channel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddTiktokChannelMutation,
  AddTiktokChannelMutationVariables
>;
export const UpdateAccessTokenForTiktokChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAccessTokenForTiktokChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateAccessTokenForTiktokChannelInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccessTokenForTiktokChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'channel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAccessTokenForTiktokChannelMutation,
  UpdateAccessTokenForTiktokChannelMutationVariables
>;
export const LatestYoutubeVideosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'latestYoutubeVideos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'youtubeChannelId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestYoutubeVideos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'youtubeChannelId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'youtubeChannelId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'coverImageUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LatestYoutubeVideosQuery,
  LatestYoutubeVideosQueryVariables
>;
export const AddYoutubeChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addYoutubeChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddYoutubeChannelInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addYoutubeChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'channel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddYoutubeChannelMutation,
  AddYoutubeChannelMutationVariables
>;
export const UpdateAccessTokenForYoutubeChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAccessTokenForYoutubeChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateAccessTokenForYoutubeChannelInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccessTokenForYoutubeChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'channel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAccessTokenForYoutubeChannelMutation,
  UpdateAccessTokenForYoutubeChannelMutationVariables
>;
